import styled from "@emotion/styled";
import Drawer from "@mui/material/Drawer";
import {
  COLOR_BLACK_TRANSPARENT,
  COLOR_WHITE_TRANSPARENT,
} from "view/theme/colors";
import Box from "@mui/material/Box";
import { flex, media } from "view/theme/mixins";

export const StyledDrawer = styled(Drawer)`
  &.MuiDrawer-root {
    width: 264px;
  }
  .MuiDrawer-paper {
    width: 264px;
    background-color: transparent;

    &::-webkit-scrollbar {
      width: 0;
    }
  }

  .MuiBackdrop-root {
    width: 100%;
    background-color: ${COLOR_BLACK_TRANSPARENT(0.5)};
    top: 58px;
  }
`;

export const StyledLeftContent = styled(Box)`
  &.MuiBox-root {
    min-width: 264px;
    ${flex({ fd: "column" })};
    background-color: ${COLOR_WHITE_TRANSPARENT(0.05)};
    backdrop-filter: blur(84px);

    ${media("desktop", true)} {
      height: 100%;
    }
  }
`;

export const StyledRightContent = styled(Box)`
  &.MuiBox-root {
    ${flex({ ai: "center", fd: "column" })};
    width: 100%;
  }
`;
