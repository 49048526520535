import queryString from "query-string";

export const mParticleEventTracker = (
  eventName: string,
  data?: Record<string, any>
) => {
  const suffix = "buzz_";
  const newEventName = `${suffix}${eventName}`;
  console.log("log mParticle event", newEventName, data);
  window.mParticle.logEvent(
    newEventName,
    window.mParticle.EventType.Other,
    data
  );
};

export const mParticleSetUserAttributes = (key: string, data: string[]) => {
  if (window.mParticle.Identity.getCurrentUser) {
    window.mParticle.Identity.getCurrentUser().setUserAttributeList(key, data);
  }
};

export const mParticleLogIn = (userId: string) => {
  const identityRequest = {
    userIdentities: {
      customerid: userId,
    },
  };
  window.mParticle.Identity.login(identityRequest);

  mParticleSetUserAttributes("product", ["light"]);
};

export const mParticleAppLaunch = () => {
  const parsedQuery = queryString.parse(window.location.search);
  const utmParams = {};

  if (window.mParticle.Identity.getCurrentUser) {
    window.mParticle.Identity.getCurrentUser().setUserAttribute(
      "from_home_screen",
      String(window.location.pathname === "/")
    );
  }

  Object.keys(parsedQuery).forEach((param) => {
    if (param.startsWith("utm_")) {
      Object.assign(utmParams, { [param]: parsedQuery[param] });
    }
  });

  console.log("log mParticle buzz launch", "buzz_launch", utmParams);
  window.mParticle.logEvent("buzz_launch", window.mParticle.EventType.Other, {
    origin: window.location.origin,
    domain: window.location.host,
    path: window.location.pathname,
    referrer: document.referrer,
    title: "Nemo",
    search: window.location.search,
    url: window.location.href,
    ...utmParams,
  });
};
