import { StyledEngineProvider, ThemeProvider } from "@mui/material/styles";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import AppProvider from "context/appContext";
import AuthContextProvider from "context/authContext";
import UserDataProvider from "context/userDataContext";
import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import InstallPromptContextProvider from "view/pages/InstallPrompt/context";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import App from "./view";
import theme from "./view/theme";
import GlobalStyles from "./view/theme/global";
import "normalize.css";
import "scroll-shadow-element";

Sentry.init({
  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 0.1,
  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 1.0,

  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [new BrowserTracing(), new Sentry.Replay()],
  tracesSampleRate: 0.0,
  tracesSampler: () => 0.0,
  environment: process.env.REACT_APP_ENV,
  denyUrls: [/localhost/, /127\.0\.0\.1/],
  release: "0.1.0",
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <AppProvider>
      <AuthContextProvider>
        <UserDataProvider>
          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
              <BrowserRouter>
                <InstallPromptContextProvider>
                  <Suspense>
                    <GlobalStyles />
                    <App />
                  </Suspense>
                </InstallPromptContextProvider>
              </BrowserRouter>
            </ThemeProvider>
          </StyledEngineProvider>
        </UserDataProvider>
      </AuthContextProvider>
    </AppProvider>
  </React.StrictMode>
);

serviceWorkerRegistration.register();
