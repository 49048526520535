import React, {
  createContext,
  FC,
  useContext,
  useEffect,
  useState,
} from "react";
import { useLocation } from "react-router-dom";
import useWebInstallPrompt from "hooks/pwa/useWebInstallPrompt";
import { isAndroid } from "tools/isInApp";
import { useAuthContext } from "context/authContext";
import { useCachedState } from "hooks/useCachedState";
import isAfter from "date-fns/isAfter";
import addDays from "date-fns/addDays";
import { handleIfAppIsInstalled } from "data/users";
import { InstallPromptContextType, InstallPromptProviderProps } from "./types";

const InstallPromptContext = createContext<InstallPromptContextType>({
  isOpen: false,
  setIsOpen: () => null,
  closePopup: () => null,
  installApp: () => null,
  handleVisitedTheme: () => null,
});

export const useInstallPrompt = () => {
  return useContext(InstallPromptContext);
};

const InstallPromptProvider: FC<InstallPromptProviderProps> = ({
  children,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [visitedThemes, setVisitedThemes] = useState<Record<string, boolean>>(
    {}
  );
  const { currentUser, user } = useAuthContext();
  const [handleWebPromptDecline, handleWebPromptAccepted] =
    useWebInstallPrompt();
  const [delayShowInstallPopup, setDelayShowInstallPopup] = useCachedState<{
    delayTime: Date;
  }>("nemo.delayShowInstallPopup");
  const location = useLocation();
  const isWPA = window.matchMedia("(display-mode: standalone)").matches;

  const timeToShowInstallPopup = delayShowInstallPopup.delayTime
    ? isAfter(new Date(), new Date(delayShowInstallPopup.delayTime))
    : true;

  useEffect(() => {
    if (
      !isWPA &&
      location.pathname === "/" &&
      Object.keys(visitedThemes).length > 1 &&
      !user?.isAppInstalled &&
      timeToShowInstallPopup
    ) {
      setIsOpen(true);
    }
  }, [location, timeToShowInstallPopup, user?.isAppInstalled, visitedThemes]);

  const installApp = async () => {
    if (!currentUser?.uid) {
      return;
    }
    if (isAndroid) {
      handleWebPromptAccepted(currentUser.uid);
    }
    handleIfAppIsInstalled(currentUser.uid);
  };

  const closePopup = async () => {
    if (!currentUser?.uid) {
      return;
    }

    setDelayShowInstallPopup({
      delayTime: addDays(new Date(), 1),
    });
    await handleWebPromptDecline(currentUser.uid);
    setIsOpen(false);
  };

  const handleVisitedTheme = (themeId: string) => {
    setVisitedThemes({
      ...visitedThemes,
      [themeId]: true,
    });
  };

  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const value = {
    isOpen,
    setIsOpen,
    closePopup,
    installApp,
    handleVisitedTheme,
  };

  return (
    <InstallPromptContext.Provider value={value}>
      {children}
    </InstallPromptContext.Provider>
  );
};

export default InstallPromptProvider;
