import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import { addYears } from "date-fns";
import { useCookies } from "react-cookie";
import { CookieSetOptions } from "universal-cookie";

export type CookieHookResult<T> = [
  cookies: any,
  setCookieValue: (value: T) => void,
  setData: Dispatch<SetStateAction<T>>,
  removeCookie: (name: string, options?: CookieSetOptions | undefined) => void
];

export const useCookieState = <T>(
  name: string,
  defaultValue?: T,
  dependencies?: string[]
): CookieHookResult<T> => {
  const [cookies, setCookie, removeCookie] = useCookies(dependencies || [name]);
  const [data, setData] = useState<T>(cookies[name] || defaultValue);

  useEffect(() => {
    setCookie(name, data, { path: "/", expires: addYears(new Date(), 1) });
  }, [data]);

  const setCookieValue = useCallback((value: T) => {
    setData(value);
    setCookie(name, value, { path: "/" });
  }, []);

  return [data, setCookieValue, setData, removeCookie];
};
