import { ReactNode, MouseEvent } from "react";
import { ButtonProps } from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import clsx from "clsx";
import CircularProgress from "@mui/material/CircularProgress";
import { COLOR_BLACK, COLOR_GREY700 } from "view/theme/colors";
import {
  StyledPrimaryButton,
  StyledLoadingContainer,
  StyledIconContainer,
  StyledTwoRowsContainer,
} from "./styled";

function PrimaryButton({
  title,
  heading,
  flex,
  onClick,
  style = "primary",
  disabled,
  variant = "contained",
  size = "medium",
  className,
  icon,
  buttonProps,
  loading,
  leftSideIcon,
}: {
  style?: "primary" | "nemo" | "gray" | "white";
  title: string | ReactNode;
  heading?: string;
  flex?: boolean;
  loading?: boolean;
  onClick?: (e: MouseEvent) => void;
  className?: string;
  variant?: "contained" | "outlined" | "ghost" | "link" | "text" | "rounded";
  size?: "medium" | "small" | "large";
  disabled?: boolean;
  icon?: ReactNode;
  leftSideIcon?: boolean;
  buttonProps?: ButtonProps;
}) {
  const renderLoading = () => (
    <StyledLoadingContainer>
      <CircularProgress className="loading" size={16} />
    </StyledLoadingContainer>
  );

  const renderButtonContent = () => (
    <>
      {icon && leftSideIcon && (
        <StyledIconContainer className="left">{icon}</StyledIconContainer>
      )}
      {heading ? (
        <StyledTwoRowsContainer>
          <Typography variant="bodyMRegular" color={COLOR_GREY700}>
            {heading}
          </Typography>
          <Typography variant="bodyLMedium" color={COLOR_BLACK}>
            {title}
          </Typography>
        </StyledTwoRowsContainer>
      ) : (
        title
      )}
      {icon && !leftSideIcon && (
        <StyledIconContainer>{icon}</StyledIconContainer>
      )}
    </>
  );

  return (
    <StyledPrimaryButton
      className={clsx(
        "data-qa-primary-button",
        variant,
        size,
        style,
        {
          loading,
          flex,
        },
        className,
        heading ? "two-rows" : ""
      )}
      onClick={(e) => {
        if (onClick) {
          onClick(e);
        }
      }}
      disabled={disabled || loading}
      {...buttonProps}
    >
      {loading ? renderLoading() : renderButtonContent()}
    </StyledPrimaryButton>
  );
}

export default PrimaryButton;
