import { createTheme } from "@mui/material/styles";
import { COLOR_WHITE } from "./colors";
import "./types";

export const FONT_FAMILY_PRIMARY = "DM Sans, sans-serif";

export default createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 680, // Mobile
      md: 1000, // Desktop
      lg: 1280,
      xl: 1920,
    },
  },
  // Figma Typography
  typography: {
    fontFamily: FONT_FAMILY_PRIMARY,

    // Heading 1 / Medium / 48px
    heading1: {
      fontFamily: FONT_FAMILY_PRIMARY,
      fontSize: "3rem",
      fontWeight: 500,
      lineHeight: "110%",
      color: COLOR_WHITE,
    },
    // Heading 2 / Regular / 40px
    heading2: {
      fontFamily: FONT_FAMILY_PRIMARY,
      fontSize: "2.5rem",
      fontWeight: 400,
      lineHeight: "110%",
      color: COLOR_WHITE,
    },
    // Heading 3 / Regular / 32px
    heading3: {
      fontFamily: FONT_FAMILY_PRIMARY,
      fontSize: "2rem",
      fontWeight: 500,
      lineHeight: "125%",
      color: COLOR_WHITE,
    },
    // Heading 4 / Medium / 24px
    heading4: {
      fontFamily: FONT_FAMILY_PRIMARY,
      fontSize: "1.5rem",
      fontWeight: 500,
      lineHeight: "130%",
      color: COLOR_WHITE,
    },
    // Heading 5 / Medium / 20px
    heading5: {
      fontFamily: FONT_FAMILY_PRIMARY,
      fontSize: "1.25rem",
      fontWeight: 500,
      lineHeight: "135%",
      color: COLOR_WHITE,
    },
    // Heading 6 / Medium / 18px
    heading6: {
      fontFamily: FONT_FAMILY_PRIMARY,
      fontSize: "1.125rem",
      fontWeight: 500,
      lineHeight: "135%",
      color: COLOR_WHITE,
    },

    // Body XLarge / SemiBold / 18px
    bodyXLSemiBold: {
      fontFamily: FONT_FAMILY_PRIMARY,
      fontSize: "1.125rem",
      fontWeight: 700,
      lineHeight: "140%",
      color: COLOR_WHITE,
    },
    // Body XLarge / Medium / 18px
    bodyXLMedium: {
      fontFamily: FONT_FAMILY_PRIMARY,
      fontSize: "1.125rem",
      fontWeight: 500,
      lineHeight: "140%",
      color: COLOR_WHITE,
    },
    // Body XLarge / Regular / 18px
    bodyXLRegular: {
      fontFamily: FONT_FAMILY_PRIMARY,
      fontSize: "1.125rem",
      fontWeight: 400,
      lineHeight: "140%",
      color: COLOR_WHITE,
    },

    // Body Large / SemiBold / 16px
    bodyLSemiBold: {
      fontFamily: FONT_FAMILY_PRIMARY,
      fontSize: "1rem",
      fontWeight: 700,
      lineHeight: "150%",
      color: COLOR_WHITE,
    },
    // Body Large / Medium / 16px
    bodyLMedium: {
      fontFamily: FONT_FAMILY_PRIMARY,
      fontSize: "1rem",
      fontWeight: 500,
      lineHeight: "150%",
      color: COLOR_WHITE,
    },
    // Body Large / Regular / 16px
    bodyLRegular: {
      fontFamily: FONT_FAMILY_PRIMARY,
      fontSize: "1rem",
      fontWeight: 400,
      lineHeight: "150%",
      color: COLOR_WHITE,
    },

    // Body Medium / SemiBold / 14px
    bodyMSemiBold: {
      fontFamily: FONT_FAMILY_PRIMARY,
      fontSize: "0.875rem",
      fontWeight: 700,
      lineHeight: "150%",
      color: COLOR_WHITE,
    },
    // Body Medium / Medium / 14px
    bodyMMedium: {
      fontFamily: FONT_FAMILY_PRIMARY,
      fontSize: "0.875rem",
      fontWeight: 500,
      lineHeight: "150%",
      color: COLOR_WHITE,
    },
    // Body Medium / Regular / 14px
    bodyMRegular: {
      fontFamily: FONT_FAMILY_PRIMARY,
      fontSize: "0.875rem",
      fontWeight: 400,
      lineHeight: "150%",
      color: COLOR_WHITE,
    },

    // Body Small / SemiBold / 12px
    bodySSemiBold: {
      fontFamily: FONT_FAMILY_PRIMARY,
      fontSize: "0.75rem",
      fontWeight: 700,
      lineHeight: "150%",
      color: COLOR_WHITE,
    },
    // Body Small / Medium / 12px
    bodySMedium: {
      fontFamily: FONT_FAMILY_PRIMARY,
      fontSize: "0.75rem",
      fontWeight: 500,
      lineHeight: "150%",
      color: COLOR_WHITE,
    },
    // Body Small / Regular / 12px
    bodySRegular: {
      fontFamily: FONT_FAMILY_PRIMARY,
      fontSize: "0.75rem",
      fontWeight: 400,
      lineHeight: "150%",
      color: COLOR_WHITE,
    },

    // Body XSmall / SemiBold / 10px
    bodyXSSemiBold: {
      fontFamily: FONT_FAMILY_PRIMARY,
      fontSize: "0.625rem",
      fontWeight: 700,
      lineHeight: "150%",
      color: COLOR_WHITE,
    },
    // Body XSmall / Medium / 10px
    bodyXSMedium: {
      fontFamily: FONT_FAMILY_PRIMARY,
      fontSize: "0.625rem",
      fontWeight: 500,
      lineHeight: "150%",
      color: COLOR_WHITE,
    },
    // Body XSmall / Regular / 10px
    bodyXSRegular: {
      fontFamily: FONT_FAMILY_PRIMARY,
      fontSize: "0.625rem",
      fontWeight: 400,
      lineHeight: "150%",
      color: COLOR_WHITE,
    },
  },
});
