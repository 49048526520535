import React, { FC } from "react";
import { FAQIcon, SupportIcon, AddToHomescreenIcon } from "view/theme/icons";
import { FAQS_PATH } from "view/routes";
import { useInstallPrompt } from "view/pages/InstallPrompt/context";
import NavItem from "./NavItem";
import { StyledDrawerContainer, StyledNavList, StyledSideBar } from "./styled";

type Props = {
  handleClose: () => void;
};

const SideBar: FC<Props> = ({ handleClose }) => {
  const { setIsOpen } = useInstallPrompt();

  const handleOpenInstallPromptModal = () => setIsOpen(true);

  const topNavList = [
    {
      to: FAQS_PATH,
      icon: <FAQIcon />,
      name: "FAQs",
    },
    {
      to: "",
      href: `mailto:${process.env.REACT_APP_SUPPORT_CONTACT}`,
      icon: <SupportIcon />,
      name: "Get Support",
    },
    {
      to: "",
      icon: <AddToHomescreenIcon />,
      name: "Add to Homescreen",
      action: () => {
        handleOpenInstallPromptModal();
        handleClose();
      },
    },
  ];

  return (
    <StyledSideBar>
      <StyledDrawerContainer>
        <StyledNavList>
          {topNavList.map((navItem, index) => (
            <NavItem
              key={`${index}- ${navItem.name}`}
              to={navItem.to}
              Icon={navItem.icon}
              name={navItem.name}
              href={navItem.href}
              onClick={navItem.action ?? handleClose}
            />
          ))}
        </StyledNavList>
      </StyledDrawerContainer>
    </StyledSideBar>
  );
};
export default SideBar;
