import { disableInstallPopup } from "data/users";

type UseIosInstallPrompt = [boolean, (userId: string) => Promise<void>];

const isIOS = (): boolean => {
  // Needed comment because navigator object is mutated when app is installed
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  if (navigator.standalone) {
    // user has already installed the app
    return false;
  }
  const { userAgent } = window.navigator;
  const isIPad = !!userAgent.match(/iPad/i);
  const isIPhone = !!userAgent.match(/iPhone/i);
  return isIPad || isIPhone;
};

const useIosInstallPrompt = (): UseIosInstallPrompt => {
  const handleUserSeeingInstallPrompt = async (userId: string) => {
    await disableInstallPopup(userId);
  };

  return [isIOS(), handleUserSeeingInstallPrompt];
};
export default useIosInstallPrompt;
