import React, { useEffect, useState } from "react";
import { disableInstallPopup } from "data/users";
import { useAuthContext } from "context/authContext";

interface BeforeInstallPromptEvent extends Event {
  readonly platforms: string[];
  readonly userChoice: Promise<{
    outcome: "accepted" | "dismissed";
    platform: string;
  }>;

  prompt(): Promise<void>;
}

declare global {
  interface WindowEventMap {
    beforeinstallprompt: BeforeInstallPromptEvent;
  }
}

type HandleInstallDeclined = (userId: string) => Promise<void>;

type UseWebInstallPrompt = [HandleInstallDeclined, HandleInstallDeclined];

const useWebInstallPrompt = (): UseWebInstallPrompt => {
  const [installPromptEvent, setInstallPromptEvent] =
    useState<BeforeInstallPromptEvent>();
  const { fetchUser } = useAuthContext();

  useEffect(() => {
    const beforeInstallPromptHandler = (event: BeforeInstallPromptEvent) => {
      event.preventDefault();
      setInstallPromptEvent(event);
    };

    window.addEventListener("beforeinstallprompt", beforeInstallPromptHandler);

    return () =>
      window.removeEventListener(
        "beforeinstallprompt",
        beforeInstallPromptHandler
      );
  }, []);

  const handleUserSeeingInstallPrompt = async (userId: string) => {
    await disableInstallPopup(userId);
    await fetchUser(userId);
  };

  const handleInstallDeclined = async (userId: string) => {
    handleUserSeeingInstallPrompt(userId);
    setInstallPromptEvent(undefined);
  };

  const handleInstallAccepted = async (userId: string) => {
    const installPrompt = installPromptEvent as BeforeInstallPromptEvent;
    installPrompt.prompt();

    installPrompt.userChoice.then((choice) => {
      if (choice.outcome !== "accepted") {
        handleUserSeeingInstallPrompt(userId);
      }
      setInstallPromptEvent(undefined);
    });
  };
  return [handleInstallDeclined, handleInstallAccepted];
};

export default useWebInstallPrompt;
