import styled from "@emotion/styled";
import Box from "@mui/material/Box";
import { flex, media } from "view/theme/mixins";
import {
  APP_BAR_HEIGHT,
  BOTTOM_NAVIGATION_HEIGHT,
  HEADER_HEIGHT,
} from "view/theme/variables";
import MuiDialog from "@mui/material/Dialog";
import { COLOR_WHITE_TRANSPARENT } from "view/theme/colors";

export const StyledAppBar = styled(Box)<{
  smallBar?: boolean;
}>`
  ${flex({ jc: "space-between", ai: "center" })};

  width: 100%;
  box-sizing: border-box;
  height: ${(props) => (props.smallBar ? HEADER_HEIGHT : APP_BAR_HEIGHT)};
  padding: 0 1rem;
`;

export const StyledAddToHomeButton = styled(Box)`
  ${flex({ fd: "row", ai: "center" })}
  height: 2rem;
  background-color: ${COLOR_WHITE_TRANSPARENT(0.1)};
  border-radius: 50px;
  padding: 0 1rem;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
`;

export const StyledDialog = styled(MuiDialog)`
  &.MuiDialog-root {
    margin: 0 auto;

    ${media("desktop", true)} {
      width: 375px;
    }

    .MuiBackdrop-root {
      top: ${BOTTOM_NAVIGATION_HEIGHT};

      ${media("desktop", true)} {
        margin: 40px auto;
        width: 375px;
      }
    }
  }
  & .MuiDialog-container {
    width: 100%;
    justify-content: flex-start;
    ${media("desktop", true)} {
      margin: 40px auto;
      width: 375px;
      height: calc(100% - 80px);
    }
  }

  & .MuiPaper-root {
    box-shadow: none;
    justify-content: center;
    background-color: transparent;
  }
`;

export const StyledMenuIconContainer = styled(Box)`
  ${flex({ jc: "center", ai: "center" })};
  height: 2rem;
  width: 2rem;
`;
