import { createCache } from "@react-hook/cache";
import { db } from "data/firebase";
import { getThemeLight } from "data/themes";
import {
  setDoc,
  collection,
  doc,
  getDoc,
  updateDoc,
  addDoc,
  orderBy,
  startAt,
  query,
  getDocs,
  deleteDoc,
  where,
  limit as limitFunction,
  Timestamp,
} from "firebase/firestore";
import omit from "lodash.omit";
import compact from "lodash.compact";
import uniqBy from "lodash.uniqby";
import { ThemeLight } from "types/theme";
import { UserType } from "types/user";
import { ViewedStoriesGroupType } from "../../types/story";

type UserCreate = {
  uid: string;
  balance: number;
  brazeId: string;
  showInstallPopup?: boolean;
  isAppInstalled?: boolean;
  product?: string;
};

export const createUser = async (user: UserCreate): Promise<void> => {
  const collectionRef = collection(db, "users");
  await setDoc(doc(collectionRef, user.uid), omit(user, "uid"));
};

export const fetchUser = async (uid: string): Promise<UserType | null> => {
  const collectionRef = collection(db, "users");
  const docRef = doc(collectionRef, uid);

  const docSnap = await getDoc(docRef);
  if (docSnap.exists()) {
    return docSnap.data() as UserType;
  }

  return null;
};

export const updateUser = async (
  uid: string,
  data: UserType
): Promise<void> => {
  const collectionRef = collection(db, "users");
  const docRef = doc(collectionRef, uid);

  await updateDoc(docRef, data);
};

export const setNotificationPermission = async (uid: string): Promise<void> => {
  const collectionRef = collection(db, "users");
  const docRef = doc(collectionRef, uid);

  await updateDoc(docRef, { notificationsPermission: true });
};

export const disableInstallPopup = async (userId: string): Promise<void> => {
  const userRef = doc(db, "users", userId);
  await updateDoc(userRef, { showInstallPopup: false });
};

export const handleIfAppIsInstalled = async (userId: string) => {
  const userRef = doc(db, "users", userId);
  await updateDoc(userRef, { isAppInstalled: true });
};

export const handleUserViewedStories = async (
  userId: string,
  viewedStories: ViewedStoriesGroupType
): Promise<void> => {
  const userRef = doc(db, "users", userId);
  await updateDoc(userRef, { viewedStories });
};

export const addFavoriteTheme = async (
  userId: string,
  themeId: string
): Promise<void> => {
  const usersCollectionRef = collection(db, "users");
  const docRef = doc(usersCollectionRef, userId);
  const favoritesCollectionRef = collection(docRef, "favorites");

  await addDoc(favoritesCollectionRef, { updatedAt: new Date(), themeId });
};

export const removeFavoriteTheme = async (
  userId: string,
  themeId: string
): Promise<void> => {
  const usersCollectionRef = collection(db, "users");
  const userDocRef = doc(usersCollectionRef, userId);
  const favoritesCollectionRef = collection(userDocRef, "favorites");
  const q = query(favoritesCollectionRef, where("themeId", "==", themeId));
  const favoriteDocs = await getDocs(q);

  if (!favoriteDocs.empty) {
    const docRef = doc(favoritesCollectionRef, favoriteDocs.docs[0].id);
    await deleteDoc(docRef);
  }
};

type GetFavoritesProps = {
  userId: string;
  startAfter?: string;
  limit?: number;
  cachedFilterThemes?: (ThemeLight & { favoriteId: string })[];
};

export const getAllFavorites = async ({
  userId,
  startAfter,
  limit,
}: GetFavoritesProps): Promise<
  { themeId: string; updatedAt: Date; favoriteId: string }[]
> => {
  try {
    const usersCollectionRef = collection(db, "users");
    const docRef = doc(usersCollectionRef, userId);
    const collectionRef = collection(docRef, "favorites");
    let params = [collectionRef, orderBy("updatedAt", "desc")];

    if (startAfter) {
      const lastDocumentSnap = await getDoc(doc(collectionRef, startAfter));
      params.push(startAt(lastDocumentSnap));
    }

    if (limit) {
      params.push(limitFunction(limit + 1));
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const q = query(...params);
    const querySnapshot = await getDocs(q);
    const favoriteNemes = querySnapshot.docs.map((doc) => ({
      ...(doc.data() as { themeId: string; updatedAt: Timestamp }),
      id: doc.id,
    }));

    return uniqBy(
      favoriteNemes.map((it) => ({
        favoriteId: it.id,
        themeId: it.themeId,
        updatedAt: it.updatedAt.toDate(),
      })),
      "themeId"
    );
  } catch (err) {
    console.error(err);
    return [];
  }
};

export const getFavorites = async ({
  userId,
  startAfter,
  limit,
  cachedFilterThemes,
}: GetFavoritesProps): Promise<(ThemeLight & { favoriteId?: string })[]> => {
  try {
    const favorites = await getAllFavorites({ userId, startAfter, limit });
    const favoriteNemes = await Promise.all(
      favorites.map((favorite) => getThemeLight(favorite.themeId))
    );
    const groupedNemes = favoriteNemes.map((neme) => {
      const favoriteId = favorites.find(
        ({ themeId }) => themeId === neme?.id
      )?.favoriteId;

      return { ...(neme as ThemeLight), favoriteId };
    });

    return uniqBy(
      [...(cachedFilterThemes || []), ...compact(groupedNemes)],
      "id"
    );
  } catch (err) {
    console.error(err);
    return [];
  }
};

export const getCachedFavoritesList = createCache((key, options) =>
  getFavorites(options)
);
