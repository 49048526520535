import Box from "@mui/material/Box";
import React, { FC, useEffect, useState } from "react";
import { getFAQsList } from "data/faqs";
import Layout from "view/components/mobile/Layout";
import Accordion, { AccordionType } from "view/components/shared/Accordion";
import { mParticleEventTracker } from "utils/mParticle";
import FAQsSkeleton from "./skeleton";

const FAQs: FC = () => {
  const [faqs, setFaqs] = useState<AccordionType[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchFAQs = async () => {
      try {
        const response = await getFAQsList();

        const normalizedFaqs4Accordion: AccordionType[] = response.map(
          (faq) => ({
            id: faq.id,
            summary: faq.question,
            details: faq.answer,
          })
        );

        setFaqs(normalizedFaqs4Accordion);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    fetchFAQs();
  }, []);

  const handleTrackAccordionActions = (expanded: boolean, content: string) => {
    mParticleEventTracker("faq_click", {
      interaction: expanded ? "expand" : "collapse",
      content,
    });
  };

  return (
    <Layout backButton title="FAQs" hideMenuIcon isSmallAppBar>
      <Box padding="1rem">
        {loading ? (
          <FAQsSkeleton />
        ) : (
          <Accordion
            items={faqs}
            theme="dark"
            handleAccordion={handleTrackAccordionActions}
          />
        )}
      </Box>
    </Layout>
  );
};

export default FAQs;
