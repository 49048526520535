import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import { FC, useState } from "react";
import { ArrowDownSimpleIcon } from "view/theme/icons";
import {
  COLOR_BLACK,
  COLOR_EXINITY_GREEN,
  COLOR_BLACK_80,
  COLOR_WHITE,
  COLOR_WHITE_80,
  COLOR_NEMO_SPRING_GREEN,
} from "view/theme/colors";
import RichText from "view/components/shared/RichText";
import {
  StyledAccordion,
  StyledAccordionSummary,
  StyledAccordionDetails,
  StyledArrowIconWrapper,
} from "./styled";

export type AccordionType = { id: string; summary: string; details: string };

interface AccordionProps {
  items: AccordionType[];
  theme?: "light" | "dark";
  handleAccordion?: (expanded: boolean, content: string) => void;
}

const Accordion: FC<AccordionProps> = ({
  items,
  theme = "light",
  handleAccordion,
}) => {
  const [expanded, setExpanded] = useState<string | false>(false);

  const handleChange =
    (panel: string, content: string) =>
    (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
      handleAccordion && handleAccordion(isExpanded, content);
    };

  const themeParams = {
    light: {
      summaryColor: COLOR_BLACK,
      detailsColor: COLOR_BLACK_80,
      arrowColor: COLOR_EXINITY_GREEN,
    },
    dark: {
      summaryColor: COLOR_WHITE,
      detailsColor: COLOR_WHITE_80,
      arrowColor: COLOR_NEMO_SPRING_GREEN,
    },
  };

  const { summaryColor, detailsColor, arrowColor } = themeParams[theme];

  return (
    <Stack data-testid="accordion-container">
      {items?.map((accordion: AccordionType, index) => (
        <StyledAccordion
          key={accordion.id}
          elevation={0}
          disableGutters
          square
          expanded={expanded === accordion.id}
          onChange={handleChange(accordion.id, accordion.summary)}
          data-testid="accordion"
        >
          <StyledAccordionSummary
            expandIcon={
              <StyledArrowIconWrapper>
                <ArrowDownSimpleIcon size={16} color={arrowColor} />
              </StyledArrowIconWrapper>
            }
            aria-controls={`${accordion.id}-content`}
            id={`${accordion.id}-header`}
            data-testid={`accordion-summary-${index}`}
          >
            <Typography variant="bodyLSemiBold" color={summaryColor}>
              {accordion.summary}
            </Typography>
          </StyledAccordionSummary>
          <StyledAccordionDetails
            data-testid={`accordion-details-${index}`}
            data-visible={expanded === accordion.id}
          >
            <Typography variant="bodyLRegular" color={detailsColor}>
              <RichText
                content={accordion.details}
                contentBackgroundColor={theme}
              />
            </Typography>
          </StyledAccordionDetails>
        </StyledAccordion>
      ))}
    </Stack>
  );
};

export default Accordion;
