export const isInApp = () =>
  navigator.userAgent.includes("Instagram") ||
  navigator.userAgent.includes("FBAN") ||
  navigator.userAgent.includes("FBAV") ||
  navigator.userAgent.includes("FBDV") ||
  navigator.userAgent.includes("FBMD") ||
  navigator.userAgent.includes("FBSN");

export const isAndroid = /android/i.test(navigator.userAgent);

export const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
export const isSafari = !!window.navigator.userAgent.match(
  /Version\/[\d.]+.*Safari/
);
