import { createCache } from "@react-hook/cache";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  limit as limitFunction,
  orderBy,
  query,
  QueryDocumentSnapshot,
  startAt,
  where,
} from "firebase/firestore";
import { getDownloadURL, ref } from "firebase/storage";
import uniqBy from "lodash.uniqby";
import { Theme, ThemeData, ThemeLight, ThemeTemplate } from "types/theme";
import { Datapoint } from "../../types/datapoint";
import { db, store } from "../firebase";
import { getNemeOrder } from "../firebase/remoteConfig";
import { get as getSymbol } from "../symbols";

type GetListProps = {
  startAfter?: string;
  filterByCategory?: string;
  limit: number;
  cachedFilterThemes?: ThemeLight[];
};

export const getList = async ({
  startAfter,
  filterByCategory,
  limit,
  cachedFilterThemes,
}: GetListProps): Promise<ThemeLight[]> => {
  try {
    const nemeOrder = getNemeOrder();

    const collectionRef = collection(db, "themes");
    let params = [
      collectionRef,
      where("status", "==", "active"),
      limitFunction(limit),
    ];

    switch (nemeOrder) {
      case "mixed":
        params.push(orderBy("mixedOrder", "asc"));
        break;
      case "manual":
        params.push(orderBy("order", "asc"));
        break;
      default:
        params.push(orderBy("order", "asc"));
    }

    if (startAfter) {
      const lastDocumentSnap = await getDoc(doc(collectionRef, startAfter));
      params.push(startAt(lastDocumentSnap));
      params.push(limitFunction(limit + 1));
    }

    if (filterByCategory !== "all") {
      params.push(where("category", "==", filterByCategory));
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const q = query(...params);
    const querySnapshot = await getDocs(q);
    const newThemes = await Promise.all(
      querySnapshot.docs.map((doc) => formatThemeLightData(doc))
    );
    const filteredThemes = newThemes.filter(
      (theme) =>
        theme.template !== ThemeTemplate.ANALYST_RATINGS_CHANGES_TEMPLATE
    );

    if (startAfter) {
      return uniqBy([...(cachedFilterThemes || []), ...filteredThemes], "id");
    }

    return filteredThemes;
  } catch (err) {
    console.error(err);
    return [];
  }
};

export const getCachedList = createCache((key, options) => getList(options));

export const getLastCreatedThemes = async (
  date: Date
): Promise<ThemeLight[]> => {
  try {
    const collectionRef = collection(db, "themes");

    const q = query(
      collectionRef,
      where("createdAt", ">=", date),
      orderBy("createdAt", "desc")
    );
    const querySnapshot = await getDocs(q);

    if (querySnapshot.empty) {
      console.warn("There is no theme with createdAt field");
      return [];
    }

    return await Promise.all(
      querySnapshot.docs.map((doc) => formatThemeLightData(doc))
    );
  } catch (err) {
    console.error(err);
    return [];
  }
};

export const getLastCreatedThemeDate = async (): Promise<Date | null> => {
  try {
    const collectionRef = collection(db, "themes");

    const q = query(
      collectionRef,
      orderBy("createdAt", "desc"),
      limitFunction(1)
    );
    const querySnapshot = await getDocs(q);

    if (querySnapshot.empty) {
      console.warn("There is no theme with createdAt field");
      return null;
    }

    const { createdAt } = querySnapshot.docs[0].data();

    return createdAt.toDate();
  } catch (err) {
    console.error(err);
    return null;
  }
};

export const getThemeLight = async (
  themeId: string
): Promise<ThemeLight | null> => {
  const collectionRef = collection(db, "themes");
  const dataDocumentSnapshot = await getDoc(doc(collectionRef, themeId));

  if (!dataDocumentSnapshot.exists()) {
    return null;
  }
  return formatThemeLightData(dataDocumentSnapshot);
};

export const getThemeDetails = async (
  themeId: string
): Promise<Theme | null> => {
  const collectionRef = collection(db, "themes");
  const dataDocumentSnapshot = await getDoc(doc(collectionRef, themeId));

  if (!dataDocumentSnapshot.exists()) {
    return null;
  }
  return formatThemeData(dataDocumentSnapshot);
};

const getSubscriptionPicture = async (): Promise<string> => {
  try {
    return await getDownloadURL(ref(store, "twitter.png"));
  } catch (e) {
    console.error(e);
    return "";
  }
};

export const getDatapointById = async (
  themeDatapointsId: string
): Promise<Datapoint | null> => {
  const collectionRef = collection(db, "datapoints");

  const dataDocumentSnapshot = await getDoc(
    doc(collectionRef, themeDatapointsId)
  );

  if (!dataDocumentSnapshot.exists()) {
    return null;
  }

  const data = dataDocumentSnapshot.data() as Datapoint;

  if (!data) {
    return null;
  }

  return {
    id: dataDocumentSnapshot.id,
    datapointName: data.datapointName,
    name: data.name,
    description: data.description,
    refinitivField: data.refinitivField,
    source: data.source,
    formula: data.formula,
    coefficient: data.coefficient,
    type: data.type,
  } as Datapoint;
};

export const getDatapointIdByName = async (name: string): Promise<string> => {
  const collectionRef = collection(db, "datapoints");

  const datapointIdQuery = query(
    collectionRef,
    where("datapointName", "==", name)
  );

  const querySnapshot = await getDocs(datapointIdQuery);

  return querySnapshot.docs[0].id;
};

const getThemePicture = async (theme: ThemeData): Promise<string> => {
  try {
    return await getDownloadURL(ref(store, theme.picture));
  } catch (e) {
    console.error(e);
    return "";
  }
};

export const formatThemeLightData = async (
  theme: QueryDocumentSnapshot
): Promise<ThemeLight> => {
  const data = theme.data() as ThemeData;

  const pictureUrl = await getThemePicture(data);

  return {
    id: theme.id,
    name: data.name || "",
    template: data.template || "",
    category: data.category || "",
    subcategory: data.subcategory || "",
    shortDescription: data.shortDescription || "",
    innerDescription: data.innerDescription || "",
    picture: pictureUrl,
    stockSymbols: data.symbols ? data.symbols.map((doc) => doc.id) : [],
    profitDelta: data.profitDelta || 0,
    newsDelta: data.newsDelta || 0,
    tags: data.tags || [],
  } as ThemeLight;
};

const themesTemplatesToSkipSymbolFetch = [
  ThemeTemplate.ANALYST_RATINGS_TEMPLATE,
  ThemeTemplate.TOP_MOVERS_TEMPLATE,
  ThemeTemplate.TRENDING_ON_REDDIT_TEMPLATE,
  ThemeTemplate.TRENDING_ON_YOUTUBE_TEMPLATE,
];

export const formatThemeData = async (
  theme: QueryDocumentSnapshot
): Promise<Theme> => {
  const data = theme.data() as ThemeData;

  const pictureUrl = await getThemePicture(data);

  const shouldSkipSymbolFetching = themesTemplatesToSkipSymbolFetch.includes(
    data.template
  );

  const symbols =
    !shouldSkipSymbolFetching && data.symbols
      ? await Promise.all(
          data.symbols.map(async (docRef) => getSymbol(docRef.id))
        )
      : [];

  const getSymbolsWithCustomDescription = () => {
    if (data.template === ThemeTemplate.CUSTOM_TEMPLATE) {
      return symbols.map((symbol) => {
        const symbolDescription = data.stockSymbols.find(
          ({ id }) => id === symbol.id
        )?.description;
        return {
          ...symbol,
          description: symbolDescription,
        };
      });
    }
    return symbols;
  };

  const subscriptions = data.subscriptions
    ? await Promise.all(
        data.subscriptions.map(async (subscription) => {
          const pictureUrl = await getSubscriptionPicture();
          return {
            // bio: subscription.bio || "",
            name: subscription.name || "",
            picture: pictureUrl,
            link: subscription.link || "",
            // username: subscription.username || "",
          };
        })
      )
    : [];

  return {
    id: theme.id,
    name: data.name || "",
    template: data.template || "",
    category: data.category || "",
    subcategory: data.subcategory || "",
    shortDescription: data.shortDescription || "",
    innerDescription: data.innerDescription || "",
    description: data.description || "",
    content: data.content || "",
    picture: pictureUrl,
    profitDelta: data.profitDelta || 0,
    newsDelta: data.newsDelta || 0,
    stockSymbols: getSymbolsWithCustomDescription(),
    subscriptions,
    notificationsSettings: data.notificationsSettings,
    questions: data.questions,
    datapoints: data.datapoints,
    youtube: data.youtube,
    featuredDatapoint: data.featuredDatapoint,
    tags: data.tags || [],
  } as Theme;
};

export const getThemesLightBySymbol = async (
  symbol: string
): Promise<ThemeLight[]> => {
  try {
    const collectionRef = collection(db, "themes");

    const nemeOrder = getNemeOrder();

    let params = [
      collectionRef,
      where("status", "==", "active"),
      where("stockSymbols", "array-contains", symbol),
    ];

    switch (nemeOrder) {
      case "mixed":
        params.push(orderBy("mixedOrder", "asc"));
        break;
      case "manual":
        params.push(orderBy("order", "asc"));
        break;
      default:
        params.push(orderBy("order", "asc"));
    }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const q = query(...params);
    const querySnapshot = await getDocs(q);

    if (querySnapshot.empty) {
      console.warn("There is no theme with symbol");
      return [];
    }

    return await Promise.all(
      querySnapshot.docs.map((doc) => formatThemeLightData(doc))
    );
  } catch (err) {
    console.error(err);
    return [];
  }
};
