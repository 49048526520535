import styled from "@emotion/styled";
import Box from "@mui/material/Box";
import { COLOR_WHITE } from "view/theme/colors";
import { keyframes } from "@emotion/react";

const changeOpacity = keyframes`
  0% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
`;

export const StyledBox = styled(Box)<{ appLaunched: boolean }>`
  min-width: 100%;
  height: inherit;
  color: ${COLOR_WHITE};
  animation: ${changeOpacity} 1.6s ease-out;
  display: ${(props) => (props.appLaunched ? "block" : "none")};
`;
