import { getValue } from "firebase/remote-config";
import { remoteConfig } from "../index";

export type NemeOrderType = "mixed" | "manual";

export enum NemeMainPageEnum {
  LIST = "list",
  MODULES = "modules",
}

export const defaultConfig = {
  intro_viz: "carousel",
  nemeOrder: "manual" as NemeOrderType,
  nemeMainPage: NemeMainPageEnum.LIST,
};

remoteConfig.defaultConfig = defaultConfig;

export const getNemeOrder = (): NemeOrderType => {
  try {
    return getValue(remoteConfig, "nemeOrder").asString() as NemeOrderType;
  } catch (e) {
    console.error(e);
    return defaultConfig.nemeOrder;
  }
};

export const getNemeMainPage = async (): Promise<NemeMainPageEnum> => {
  try {
    const nemePage = await getValue(remoteConfig, "nemeMainPage").asString();
    return nemePage as NemeMainPageEnum;
  } catch (e) {
    console.error(e);
    return defaultConfig.nemeMainPage;
  }
};
