import styled from "@emotion/styled";
import Box from "@mui/material/Box";
import { flexColumn, media } from "view/theme/mixins";
import { COLOR_BLACK } from "view/theme/colors";

export const StyledPageContainer = styled(Box)<{
  fullHeight?: boolean;
}>`
  ${flexColumn()};
  height: ${(props) => (props.fullHeight ? "100%" : "unset")};
  min-height: ${(props) => (props.fullHeight ? "100vh" : "unset")};

  ${media("mobile", true)} {
    height: 100%;
    overflow: scroll;
    width: 375px;
    position: fixed;
    max-height: calc(100% - 80px);
    min-height: ${(props) =>
      props.fullHeight ? "calc(100% - 80px)" : "unset"};

    ::-webkit-scrollbar {
      display: none;
      -ms-overflow-style: none;
      scrollbar-width: none;
    }
  }
`;

export const StyledContent = styled(Box)<{
  hideAppBar: boolean;
}>`
  ${flexColumn()};
`;

export const StyledHeaderWrapper = styled(Box)`
  ${flexColumn()};
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 10;
  background-color: ${COLOR_BLACK};

  ${media("mobile", true)} {
    width: 375px;
    right: auto;
    left: auto;
  }
`;
