import styled from "@emotion/styled";
import Accordion from "@mui/material/Accordion";
import Box from "@mui/material/Box";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import { COLOR_BLACK_10 } from "view/theme/colors";
import { flex } from "view/theme/mixins";

export const StyledAccordion = styled(Accordion)`
  &.MuiPaper-root {
    background-color: transparent;
  }

  margin: 0;

  &:before {
    background-color: ${COLOR_BLACK_10};
  }

  &:not(style) + :not(style) {
    margin: 0;
  }
`;

export const StyledAccordionSummary = styled(MuiAccordionSummary)`
  & .MuiAccordionSummary-expandIconWrapper.Mui-expanded {
    transform: rotate(90deg);
  }

  padding: 0;
  column-gap: 1rem;
`;

export const StyledAccordionDetails = styled(MuiAccordionDetails)`
  padding: 0 0 0.75rem;
`;

export const StyledArrowIconWrapper = styled(Box)`
  ${flex()};
`;
