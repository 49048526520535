import { collection, getDocs, orderBy, query, where } from "firebase/firestore";
import { FAQ, FAQStatus, Environment } from "types/faqs";
import { db } from "../firebase";

export const getFAQsList = async (): Promise<FAQ[]> => {
  const collectionRef = collection(db, "faqs");

  let params = [collectionRef, where("status", "==", FAQStatus.PUBLISHED)];

  params.push(orderBy("order", "asc"));
  params.push(where("environment", "array-contains", Environment.BUZZ));

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const q = query(...params);

  const querySnapshot = await getDocs(q);

  return Promise.all(
    querySnapshot.docs.map(
      (doc) =>
        ({
          id: doc.id,
          ...doc.data(),
        } as FAQ)
    )
  );
};
