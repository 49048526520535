import { FC, ReactNode } from "react";
import HideOnScroll from "view/components/shared/HideOnScroll";
import AppBar from "./components/AppBar";
import {
  StyledPageContainer,
  StyledContent,
  StyledHeaderWrapper,
} from "./styled";

type LayoutProps = {
  children: ReactNode;
  headerChildren?: ReactNode;
  className?: string;
  hideAppBar?: boolean;
  backButton?: boolean;
  hideMenuIcon?: boolean;
  isSmallAppBar?: boolean;
  title?: string;
};

const Layout: FC<LayoutProps> = (props) => {
  const {
    hideAppBar = false,
    headerChildren,
    isSmallAppBar,
    hideMenuIcon,
    title,
  } = props;

  return (
    <StyledPageContainer className={props.className} id="layout-container">
      {!hideAppBar && (
        <HideOnScroll>
          <StyledHeaderWrapper>
            <AppBar
              backButton={props.backButton}
              hideMenuIcon={hideMenuIcon}
              smallAppBar={isSmallAppBar}
              title={title}
            />
            {headerChildren}
          </StyledHeaderWrapper>
        </HideOnScroll>
      )}
      <StyledContent hideAppBar={hideAppBar}>{props.children}</StyledContent>
    </StyledPageContainer>
  );
};

export default Layout;
