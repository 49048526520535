import { useState } from "react";

type HookResult<T> = [state: T, setState: (value: T) => void];

export const useCachedState = <T>(
  localKey: string,
  defaultValue?: T
): HookResult<T> => {
  const [state, setState] = useState<T>(() => {
    const saved = localStorage.getItem(localKey);
    if (saved) {
      return JSON.parse(saved);
    }
    return defaultValue || "";
  });

  const setLocalValue = (value: T) => {
    setState(value);

    if (value === null) {
      return localStorage.removeItem(localKey);
    }

    return localStorage.setItem(localKey, JSON.stringify(value));
  };

  return [state, setLocalValue];
};
