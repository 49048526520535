import styled from "@emotion/styled";
import Box from "@mui/material/Box";
import { css } from "@emotion/react";
import {
  COLOR_NEMO_LIGHT_BLUE,
  COLOR_NEMO_SPRING_GREEN,
} from "view/theme/colors";

const handleShowText = (showMore?: boolean, stockDescription?: boolean) => {
  if (!showMore && stockDescription) {
    return css`
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 6;
    `;
  }
};

export const StyledBox = styled(Box, {
  shouldForwardProp: (prop) =>
    prop !== "stockDescription" && prop !== "showMore",
})<{
  showMore?: boolean;
  stockDescription?: boolean;
  contentBackgroundColor?: "light" | "dark";
}>`
  max-width: 100%;
  overflow: hidden;
  ${(props) => handleShowText(props.showMore, props.stockDescription)};

  & ol {
    padding-left: 20px;
  }

  & p {
    margin: 0;
  }

  & p > span {
    margin: 0;
  }

  & img {
    max-width: 100%;
  }

  a {
    color: ${(props) =>
      props.contentBackgroundColor === "light"
        ? COLOR_NEMO_LIGHT_BLUE
        : COLOR_NEMO_SPRING_GREEN}
`;
