import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import lottie from "lottie-web/build/player/lottie_light";
import { RemoveScroll } from "react-remove-scroll";
import { FC, useEffect, useRef, useState } from "react";
import { useAuthContext } from "context/authContext";
import { useNavigate } from "react-router-dom";
import { THEMES_PATH } from "view/routes";
import { COLOR_WHITE } from "view/theme/colors";
import { ArrowBackwardIcon } from "view/theme/icons";
import { NemoLongLogo } from "view/theme/logos";
import { mParticleEventTracker } from "utils/mParticle";
import InstallPrompt from "view/pages/InstallPrompt";
import {
  StyledLeftContent,
  StyledRightContent,
} from "view/components/shared/DrawerContainer/styled";
import RightTransition from "view/components/shared/RightTransition";
import SideBar from "view/components/shared/SideBar";
import FlexCenter from "view/components/shared/Layout/components/FlexCenter";

import menu from "./lottie/menu.json";
import { StyledAppBar, StyledDialog, StyledMenuIconContainer } from "./styled";

type AppBarProps = {
  backButton?: boolean;
  hideMenuIcon?: boolean;
  title?: string;
  handleBackClick?: () => void;
  smallAppBar?: boolean;
};

const AppBar: FC<AppBarProps> = ({
  backButton,
  hideMenuIcon,
  title,
  smallAppBar,
  handleBackClick,
}) => {
  const [openSideMenu, setOpenSideMenu] = useState(false);
  const animationContainer = useRef(null);
  const animation = useRef(null) as any;

  const navigate = useNavigate();
  const { user } = useAuthContext();
  const isWPA = window.matchMedia("(display-mode: standalone)").matches;

  useEffect(() => {
    if (animationContainer.current) {
      animation.current = lottie.loadAnimation({
        container: animationContainer.current,
        renderer: "svg",
        loop: false,
        autoplay: false,
        animationData: menu,
      });
      return () => animation.current?.destroy();
    }
  }, []);

  const handleClickSideMenu = () => {
    setOpenSideMenu((prev) => !prev);
    animation.current?.setDirection(openSideMenu ? -1 : 1);
    animation.current?.play();
  };

  const handleBack = () => {
    if (handleBackClick) {
      return handleBackClick();
    }

    return navigate(-1);
  };

  useEffect(() => {
    if (location.pathname.includes("/themes/")) {
      window.history.pushState(null, document.title, window.location.href);
      const onPopState = () => {
        window.history.pushState(null, document.title, window.location.href);
        mParticleEventTracker("themes_main");
        navigate(-1);
      };
      window.addEventListener("popstate", onPopState);

      return () => {
        window.removeEventListener("popstate", onPopState);
      };
    }
  }, []);

  return (
    <RemoveScroll enabled={openSideMenu}>
      <StyledAppBar smallBar={smallAppBar}>
        {backButton || handleBackClick ? (
          <Box display="flex" onClick={handleBack} data-testid="back-button">
            <ArrowBackwardIcon color={COLOR_WHITE} />
          </Box>
        ) : (
          <Box display="flex" onClick={() => navigate(THEMES_PATH)}>
            <NemoLongLogo />
          </Box>
        )}
        {title && <Typography variant="bodyLSemiBold">{title}</Typography>}
        <FlexCenter width="32px">
          {!hideMenuIcon && (
            <StyledMenuIconContainer onClick={handleClickSideMenu}>
              <Box height="24px" width="24px" ref={animationContainer} />
            </StyledMenuIconContainer>
          )}
        </FlexCenter>
      </StyledAppBar>
      <StyledDialog
        open={openSideMenu}
        onClose={handleClickSideMenu}
        TransitionComponent={RightTransition}
        transitionDuration={700}
        fullScreen
      >
        <Box display="flex" justifyContent="space-between" height="100%">
          <StyledLeftContent>
            <SideBar handleClose={handleClickSideMenu} />
          </StyledLeftContent>
          <StyledRightContent onClick={handleClickSideMenu} />
        </Box>
      </StyledDialog>
      <InstallPrompt />
    </RemoveScroll>
  );
};

export default AppBar;
