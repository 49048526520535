import { DocumentSnapshot } from "firebase/firestore";

export enum FAQStatus {
  PUBLISHED = "published",
  DRAFT = "draft",
}

export enum Environment {
  BUZZ = "buzz",
  APP = "app",
}

export type FAQ = {
  id: string;
  question: string;
  answer: string;
  environment: Environment[];
  status: FAQStatus;
  order: number;
};

export type FAQData = {
  totalCount: number;
  items: FAQ[];
  endListItem?: DocumentSnapshot;
  startListItem?: DocumentSnapshot;
};

export type CreatedFAQ = Omit<FAQ, "id" | "order">;
