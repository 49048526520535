import * as braze from "@braze/web-sdk";

braze.initialize(process.env.REACT_APP_BRAZE_API_KEY || "", {
  baseUrl: process.env.REACT_APP_BRAZE_BASE_URL || "",
  enableLogging: process.env.REACT_APP_ENV !== "production",
  safariWebsitePushId: process.env.REACT_APP_BRAZE_SAFARI_WEBSITE_PUSH_ID || "",
});

braze.automaticallyShowInAppMessages();

braze.openSession();

export const requestPushAccess = () => {
  braze.requestPushPermission();
};

export const isSupported = () => {
  return braze.isPushSupported();
};

export const setBrazeUserAttribute = (
  key: string,
  value: number | boolean | Date | string | string[] | null
) => {
  const user = braze.getUser();

  if (!user) {
    throw new Error("Braze user is not defined");
  }

  if (key) {
    user.setCustomUserAttribute(`${key}_subscriptions`, value);
  }
};

export default braze;
