export const ShareIcon = () => (
  <svg
    width="19"
    height="24"
    viewBox="0 0 19 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.48975 15.3857C9.93066 15.3857 10.3101 15.0166 10.3101 14.5859V4.05518L10.2485 2.51709L10.9355 3.24512L12.4941 4.90625C12.6377 5.07031 12.8428 5.15234 13.0479 5.15234C13.4683 5.15234 13.7964 4.84473 13.7964 4.42432C13.7964 4.20898 13.7041 4.04492 13.5503 3.89111L10.0845 0.54834C9.87939 0.343262 9.70508 0.271484 9.48975 0.271484C9.28467 0.271484 9.11035 0.343262 8.89502 0.54834L5.4292 3.89111C5.27539 4.04492 5.19336 4.20898 5.19336 4.42432C5.19336 4.84473 5.50098 5.15234 5.93164 5.15234C6.12646 5.15234 6.35205 5.07031 6.49561 4.90625L8.04395 3.24512L8.74121 2.51709L8.67969 4.05518V14.5859C8.67969 15.0166 9.04883 15.3857 9.48975 15.3857ZM3.61426 23.4146H15.3755C17.5186 23.4146 18.5952 22.3481 18.5952 20.2358V10.0024C18.5952 7.89014 17.5186 6.82373 15.3755 6.82373H12.5146V8.47461H15.3447C16.3599 8.47461 16.9443 9.02832 16.9443 10.0947V20.1436C16.9443 21.21 16.3599 21.7637 15.3447 21.7637H3.63477C2.60938 21.7637 2.04541 21.21 2.04541 20.1436V10.0947C2.04541 9.02832 2.60938 8.47461 3.63477 8.47461H6.4751V6.82373H3.61426C1.47119 6.82373 0.394531 7.89014 0.394531 10.0024V20.2358C0.394531 22.3481 1.47119 23.4146 3.61426 23.4146Z"
      fill="#0A84FF"
    />
  </svg>
);
