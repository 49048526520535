import React from "react";
import clsx from "clsx";
import { StyledBox } from "./styled";

type RichTextProps = {
  content?: string;
  className?: string;
  showMore?: boolean;
  stockDescription?: boolean;
  contentBackgroundColor?: "light" | "dark";
};

const RichText: React.FC<RichTextProps> = ({
  content,
  className = "",
  showMore,
  stockDescription,
  contentBackgroundColor,
}) => {
  if (!content) {
    return null;
  }

  return (
    <StyledBox
      data-qa="description"
      showMore={showMore}
      stockDescription={stockDescription}
      contentBackgroundColor={contentBackgroundColor}
      className={clsx(className)}
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{
        __html: content,
      }}
    />
  );
};

export default RichText;
