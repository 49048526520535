import { styled } from "@mui/material/styles";
import { COLOR_WHITE_TRANSPARENT } from "view/theme/colors";
import ListItemIcon from "@mui/material/ListItemIcon";
import Box from "@mui/material/Box";
import MenuList from "@mui/material/MenuList";
import MenuItem from "@mui/material/MenuItem";
import { flex, media } from "view/theme/mixins";

interface MenuItemProps {
  to?: string;
  href?: string;
  component?: React.ElementType;
}

export const StyledNavItemIcon = styled(ListItemIcon)<{ color: string }>`
  & path {
    fill: ${(props) => props.color};
  }
`;

export const StyledSideBar = styled(Box)`
  &.MuiBox-root {
    ${flex({ fd: "column" })};
    height: 100%;
    background-color: ${COLOR_WHITE_TRANSPARENT(0.05)};
    backdrop-filter: blur(84px);
    padding: 1rem;
  }
`;

export const StyledDrawerContainer = styled(Box)`
  &.MuiBox-root {
    ${flex({ fd: "column", jc: "space-between" })};
    height: 100%;
  }
`;

export const StyledNavList = styled(MenuList)`
  ${flex({ fd: "column" })};
  outline: none;

  &.MuiList-padding {
    padding: 0;
  }
`;

export const StyledMenuItem = styled(MenuItem)<MenuItemProps>`
  padding: 0;

  .MuiListItemIcon-root {
    min-width: 0;
  }

  :not(:last-of-type) {
    ${media("desktop", true)} {
      margin-bottom: 1.5rem;
    }
  }

  &.Mui-disabled {
    color: ${COLOR_WHITE_TRANSPARENT(0.4)};
    opacity: 1;
  }

  &.Mui-selected {
    background-color: transparent;

    &:hover {
      background-color: transparent;
    }
  }
`;
