import useEffectOnce from "hooks/useEffectOnce";
import { FC, lazy, useEffect, useState } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { mParticleAppLaunch } from "utils/mParticle";
import { lazyRetry } from "utils/core";
import { remoteConfig } from "data/firebase";
import { fetchAndActivate } from "firebase/remote-config";
import { StyledBox } from "./styled";
import { FAQS_PATH } from "./routes";
import FAQs from "./pages/FAQs";

const Themes = lazy(() => lazyRetry(() => import("./pages/Themes")));
const Theme = lazy(() => lazyRetry(() => import("./pages/Theme")));
const Symbol = lazy(() => lazyRetry(() => import("./pages/Symbol")));

const CookieBanner = lazy(() =>
  lazyRetry(() => import("view/components/mobile/CookieBanner"))
);
const InstallPrompt = lazy(() =>
  lazyRetry(() => import("./pages/InstallPrompt"))
);

const App: FC = () => {
  const [appLaunched, setAppLaunched] = useState(false);
  const [isAppReady, setIsAppReady] = useState(false);
  const [isLaunchStarted, setIsLaunchStarted] = useState(false);
  const [isRemoteConfigFetched, setIsRemoteConfigFetched] = useState(false);

  useEffectOnce(() => {
    setTimeout(() => {
      mParticleAppLaunch();
    }, 1000);
  });

  const handleAppLaunched = () => {
    setIsAppReady(true);
  };

  useEffect(() => {
    fetchAndActivate(remoteConfig)
      .then((res) => {
        console.log("remote config activated", res);
      })
      .catch((error) => {
        console.log("remote config activation error", error);
      })
      .finally(() => {
        setIsRemoteConfigFetched(true);
      });
  }, []);

  useEffect(() => {
    // !isLaunchStarted || isAppReady
    // ^^description: if we haven't started launch yet we assume that there is no launch functionality supported on the page
    if (!isLaunchStarted || isAppReady) {
      setAppLaunched(true);
    }
  }, [isAppReady, isLaunchStarted]);

  if (!isRemoteConfigFetched) {
    return null;
  }

  return (
    <>
      <StyledBox appLaunched={appLaunched}>
        <Routes>
          <Route
            index
            element={
              <Themes
                onStartLoad={() => setIsLaunchStarted(true)}
                onLoad={handleAppLaunched}
              />
            }
          />
          <Route path="themes/" element={<Navigate to="/" />} />
          <Route path="themes/:id" element={<Theme />} />
          <Route path="trade/:symbol" element={<Symbol />} />
          <Route path={FAQS_PATH} element={<FAQs />} />
          <Route path="/*" element={<Navigate to="/" />} />
        </Routes>
      </StyledBox>
      <InstallPrompt />
      <CookieBanner appLaunched={appLaunched} />
    </>
  );
};

export default App;
