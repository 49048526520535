import React, { FC, ReactNode } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { NavLink, useMatch } from "react-router-dom";
import { COLOR_WHITE_TRANSPARENT, COLOR_WHITE } from "view/theme/colors";
import Link from "@mui/material/Link";
import { StyledMenuItem, StyledNavItemIcon } from "./styled";

export type MenuItemProps = {
  Icon?: ReactNode;
  onClick?: () => void;
  name: string;
  to: string;
  paths?: string[];
  disabled?: boolean;
  hidden?: boolean;
  href?: string;
};

const NavItem: FC<MenuItemProps> = (props) => {
  const { Icon, to, name, onClick, disabled, paths, href } = props;
  const match = useMatch(to);
  const isSelected = (path: string) =>
    match?.pathname === path ||
    paths?.some((it) => useMatch(it)?.pathname === location.pathname);

  const activeColor = isSelected(to)
    ? COLOR_WHITE
    : COLOR_WHITE_TRANSPARENT(0.4);

  if (props?.hidden) {
    return null;
  }

  return (
    <StyledMenuItem
      to={to}
      href={href}
      onClick={onClick}
      component={href ? Link : NavLink}
      disabled={disabled}
      disableRipple
      selected
    >
      <StyledNavItemIcon
        color={isSelected(to) ? COLOR_WHITE : COLOR_WHITE_TRANSPARENT(0.4)}
      >
        {Icon}
      </StyledNavItemIcon>
      <Box display="flex" justifyContent="space-between" width={1} ml=".5rem">
        <Typography
          variant="bodyXLMedium"
          color={activeColor}
          style={{ alignSelf: "center" }}
        >
          {name}
        </Typography>
      </Box>
    </StyledMenuItem>
  );
};

export default NavItem;
