import { useCachedState } from "hooks/useCachedState";
import debounce from "lodash.debounce";
import { createUser, fetchUser as fetchUserRequest } from "data/users";
import { createContext, FC, useContext, useEffect, useState } from "react";
import { getAuth, onAuthStateChanged, signInAnonymously } from "firebase/auth";
import { UserType } from "types/user";
import braze from "utils/braze";
import { mParticleLogIn } from "utils/mParticle";
import { AuthContextType, AuthContextProviderProps, UserOrNull } from "./types";

const AuthContextContext = createContext<AuthContextType>({
  currentUser: null,
  user: null,
  setUser: () => null,
  fetchUser: () => Promise.resolve(undefined),
});

export const useAuthContext = () => {
  return useContext(AuthContextContext);
};

const AuthContextProvider: FC<AuthContextProviderProps> = ({ children }) => {
  const auth = getAuth();
  const [currentUser, setCurrentUser] = useState<UserOrNull>(null);
  const [user, setUser] = useCachedState<UserType | null>("nemo.user", null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (userResponse) => {
      if (userResponse) {
        fetchUser(userResponse.uid);
      }

      setCurrentUser(userResponse);
      setLoading(false);
    });

    return unsubscribe;
  }, []);

  useEffect(() => {
    if (currentUser?.uid) {
      mParticleLogIn(currentUser.uid);
    }
  }, [currentUser?.uid]);

  const fetchUser = debounce(async (uid?: string) => {
    if (uid) {
      const userResponse = await fetchUserRequest(uid);
      setUser(userResponse);
    } else if (currentUser) {
      const userResponse = await fetchUserRequest(currentUser.uid);
      setUser(userResponse);
    }
  }, 500);

  const createAccount = async () => {
    const credential = await signInAnonymously(auth);
    const { user } = credential;

    await createUser({
      uid: user.uid,
      balance: 0,
      brazeId: user.uid,
      product: "light",
    });
    braze.changeUser(user.uid);
  };

  useEffect(() => {
    if (!loading && !currentUser) {
      createAccount();
    }
  }, [loading, currentUser]);

  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const value = {
    currentUser,
    user,
    setUser,
    fetchUser,
  };

  return (
    <AuthContextContext.Provider value={value}>
      {children}
    </AuthContextContext.Provider>
  );
};

export default AuthContextProvider;
