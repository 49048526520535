import { css } from "@emotion/react";
import { CSSProperties } from "react";
import { COLOR_GREY200 } from "./colors";

export type FlexDirectionTypes =
  | "column"
  | "column-reverse"
  | "row"
  | "row-reverse";

type FlexType = {
  ai?: string;
  jc?: string;
  fd?: FlexDirectionTypes;
};

export type JustifyContentTypes =
  | "stretch"
  | "space-evenly"
  | "space-around"
  | "space-between"
  | "end"
  | "start"
  | "center"
  | "right"
  | "left"
  | "flex-end"
  | "flex-start"
  | "inherit"
  | "initial"
  | "unset";

export const flex = (props?: FlexType) => {
  const alignItems = props?.ai || "inherit";
  const justifyContent = props?.jc || "inherit";
  const flexDirection = props?.fd || "row";

  return css`
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;

    // Align Items
    ${alignItems === "flex-start" &&
    `
      -webkit-box-align: start;
      -moz-box-align: start;
      -ms-flex-align: start;
    `}
    ${alignItems === "flex-end" &&
    `
      -webkit-box-align: end;
      -moz-box-align: end;
      -ms-flex-align: end;
    `}
    ${alignItems !== "flex-end" &&
    alignItems !== "flex-start" &&
    `
      -webkit-box-align: ${alignItems};
      -moz-box-align: ${alignItems};
      -ms-flex-align: ${alignItems};
    `}
    -webkit-align-items: ${alignItems};
    align-items: ${alignItems};

    // Justify Content
    ${justifyContent === "flex-start" &&
    `
      -webkit-box-pack: start;
      -moz-box-pack: start;
      -ms-flex-pack: start;
    `}
    ${justifyContent === "flex-end" &&
    `
      -webkit-box-pack: end;
      -moz-box-pack: end;
      -ms-flex-pack: end;
    `}
    ${justifyContent === "space-between" &&
    `
      -webkit-box-pack: justify;
      -moz-box-pack: justify;
      -ms-flex-pack: justify;
    `}
    ${justifyContent === "space-around" &&
    `
      -ms-flex-pack: distribute;
    `}
    ${justifyContent !== "flex-end" &&
    justifyContent !== "flex-start" &&
    justifyContent !== "space-between" &&
    justifyContent !== "space-around" &&
    `
      -webkit-box-pack: ${justifyContent};
      -moz-box-pack: ${justifyContent};
      -ms-flex-pack: ${justifyContent};
    `}
    -webkit-justify-content: ${justifyContent};
    justify-content: ${justifyContent};

    // Flex Direction
    flex-direction: ${flexDirection};
    -ms-flex-direction: ${flexDirection};
    -webkit-flex-direction: ${flexDirection};
  `;
};

type FlexCenterType = {
  fd?: FlexDirectionTypes;
};

export const flexCenter = (props?: FlexCenterType) =>
  flex({ ai: "center", jc: "center", fd: props?.fd });

type FlexColumnType = {
  ai?: string;
  jc?: JustifyContentTypes;
};

export const flexColumn = (props?: FlexColumnType) =>
  flex({ ai: props?.ai, jc: props?.jc, fd: "column" });

export const border = (width = 1, color = COLOR_GREY200) => css`
  border: ${width}px solid ${color};
`;

export type BreakpointsType = "mobile" | "desktop";

export const bp = {
  mobile: 680, // Mobile
  desktop: 1000, // Desktop
};

export const media = (bt?: BreakpointsType, minWidth?: boolean) => {
  const breakpoint = bt || "mobile";
  const bpArray = Object.keys(bp).map((key) => [
    key,
    bp[key as keyof typeof bp],
  ]);

  const [result] = bpArray.reduce((acc, [name, size]) => {
    if (breakpoint === name) {
      return [
        ...acc,
        `@media (${minWidth ? "min-width" : "max-width"}: ${size}px)`,
      ];
    }

    return acc;
  }, []);

  return result;
};

export const text = (textStyle: CSSProperties) => {
  const { fontFamily, fontSize, fontWeight, lineHeight, color } = textStyle;

  return css`
    font-family: ${fontFamily};
    font-size: ${fontSize};
    font-weight: ${fontWeight};
    line-height: ${lineHeight};
    color: ${color};
  `;
};

export const transition = (duration?: number) => css`
  transition: all ${duration || 0.25}s ease-in-out;
`;

export const slideNext = () => {
  const interval = 0.3;
  const opacity0 = 0;
  const opacity100 = 1;

  const slideNext = `0% {
    opacity: ${opacity0};
    transform: translateX(70px);
  }
  100% {
    opacity: ${opacity100};
    transform: translateX(0);
  }`;

  return css`
    animation: slideNext ${interval}s linear;
    -webkit-animation: slideNext ${interval}s linear;
    -moz-animation: slideNext ${interval}s linear;
    -o-animation: slideNext ${interval}s linear;
    -ms-animation: slideNext ${interval}s linear;

    @keyframes slideNext {
      ${slideNext}
    }

    @-moz-keyframes slideNext {
      ${slideNext}
    }

    @-webkit-keyframes slideNext {
      ${slideNext}
    }

    @-o-keyframes slideNext {
      ${slideNext}
    }

    @-ms-keyframes slideNext {
      ${slideNext}
    }
  `;
};

export const slideBack = () => {
  const interval = 0.3;
  const opacity0 = 0;
  const opacity100 = 1;

  const slideBack = `0% {
    opacity: ${opacity0};
    transform: translateX(-70px);
  }
  100% {
    opacity: ${opacity100};
    transform: translateX(0);
  }`;

  return css`
    animation: slideBack ${interval}s linear;
    -webkit-animation: slideBack ${interval}s linear;
    -moz-animation: slideBack ${interval}s linear;
    -o-animation: slideBack ${interval}s linear;
    -ms-animation: slideBack ${interval}s linear;

    @keyframes slideBack {
      ${slideBack}
    }

    @-moz-keyframes slideBack {
      ${slideBack}
    }

    @-webkit-keyframes slideBack {
      ${slideBack}
    }

    @-o-keyframes slideBack {
      ${slideBack}
    }

    @-ms-keyframes slideBack {
      ${slideBack}
    }
  `;
};
