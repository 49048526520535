import { useAuthContext } from "context/authContext";
import { getAllFavorites } from "data/users";
import { useCookieState } from "hooks/useCookieState";
import { createContext, FC, useContext, useEffect } from "react";
import { UserDataType, UserDataProviderProps } from "./types";

const UserDataContext = createContext<UserDataType>({
  favorites: [],
  setFavorites: () => null,
});

export const useUserData = () => {
  return useContext(UserDataContext);
};

const UserDataProvider: FC<UserDataProviderProps> = ({ children }) => {
  const { currentUser } = useAuthContext();
  const [favorites, _, setFavorites] = useCookieState<string[]>("favNemes", []);

  useEffect(() => {
    const fetchFavorites = async () => {
      if (!currentUser?.uid) {
        return;
      }

      const favoriteNemes = await getAllFavorites({ userId: currentUser.uid });
      setFavorites(favoriteNemes.map(({ themeId }) => themeId));
    };

    fetchFavorites();
  }, [currentUser?.uid]);

  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const value = {
    favorites,
    setFavorites,
  };

  return (
    <UserDataContext.Provider value={value}>
      {children}
    </UserDataContext.Provider>
  );
};

export default UserDataProvider;
