import styled from "@emotion/styled";
import Box from "@mui/material/Box";
import {
  flex,
  FlexDirectionTypes,
  JustifyContentTypes,
} from "view/theme/mixins";

const FlexCenter = styled(Box)<{
  fd?: FlexDirectionTypes;
  jc?: JustifyContentTypes;
  ai?: string;
}>`
  ${(props) => flex({ ai: props?.ai || "center", jc: props.jc, fd: props.fd })};
`;

export default FlexCenter;
