import {
  limit as limitFunction,
  collection,
  getDoc,
  doc,
  getDocs,
  query,
  QueryDocumentSnapshot,
  startAt,
  where,
  orderBy,
} from "firebase/firestore";
import { getDownloadURL, ref } from "firebase/storage";
import {
  AnalyticsSortingEnum,
  BrandwatchSortingEnum,
  BrandwatchTimeEnum,
  Price,
  PriceData,
  StockFilterPeriodType,
  StockSymbol,
  StockSymbolData,
  StockSymbolLight,
  StockSymbolLightWithRedditAnalysis,
  StockSymbolLightWithTwitterAnalysis,
  StockSymbolLightWithYoutubeAnalysis,
  TopMoversSortingEnum,
} from "types/symbol";
import { db, store } from "../firebase";

type GetListProps = {
  startAfter?: string;
  filter?: string;
  limit: number;
};

export const getList = async ({
  startAfter,
  filter,
  limit,
}: GetListProps): Promise<StockSymbolLight[]> => {
  try {
    const collectionRef = collection(db, "symbols");
    let params = [collectionRef, limitFunction(limit)];

    if (startAfter) {
      const lastDocumentSnap = await getDoc(doc(collectionRef, startAfter));
      params.push(startAt(lastDocumentSnap));
    }

    if (filter) {
      // params.push(where("name", "in", [filter]));
      // params.push(where("label", "in", [filter]));
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const q = query(...params);
    const querySnapshot = await getDocs(q);

    return Promise.all(
      querySnapshot.docs.map((doc) => formatSymbolLightData(doc))
    );
  } catch (err) {
    console.error(err);
    return [];
  }
};

type GetListByMovementProps = {
  startAfter?: string;
  timeframe: StockFilterPeriodType;
  filterBy: TopMoversSortingEnum;
  limit?: number;
};

export const getListByMovement = async ({
  startAfter,
  timeframe,
  filterBy,
  limit = 50,
}: GetListByMovementProps): Promise<StockSymbolLight[]> => {
  try {
    const collectionRef = collection(db, "symbols");
    let params = [collectionRef, limitFunction(limit)];

    if (startAfter) {
      const lastDocumentSnap = await getDoc(doc(collectionRef, startAfter));
      params.push(startAt(lastDocumentSnap));
    }

    if (filterBy === "all") {
      switch (timeframe) {
        case "1day":
          params.push(orderBy("lastDayPriceDeltaPercentAbsolute", "desc"));
          break;
        case "1week":
          params.push(orderBy("lastWeekPriceDeltaPercentAbsolute", "desc"));
          break;
        case "1month":
          params.push(orderBy("lastMonthPriceDeltaPercentAbsolute", "desc"));
          break;
        default:
          break;
      }
    }
    if (filterBy === "gainers") {
      switch (timeframe) {
        case "1day":
          params.push(orderBy("lastDayPriceDeltaPercent", "desc"));
          params.push(where("lastDayPriceDeltaPercent", ">", 0));
          break;
        case "1week":
          params.push(orderBy("lastWeekPriceDeltaPercent", "desc"));
          params.push(where("lastWeekPriceDeltaPercent", ">", 0));
          break;
        case "1month":
          params.push(orderBy("lastMonthPriceDeltaPercent", "desc"));
          params.push(where("lastMonthPriceDeltaPercent", ">", 0));
          break;
        default:
          break;
      }
    }
    if (filterBy === "losers") {
      switch (timeframe) {
        case "1day":
          params.push(orderBy("lastDayPriceDeltaPercent", "asc"));
          params.push(where("lastDayPriceDeltaPercent", "<", 0));
          break;
        case "1week":
          params.push(orderBy("lastWeekPriceDeltaPercent", "asc"));
          params.push(where("lastWeekPriceDeltaPercent", "<", 0));
          break;
        case "1month":
          params.push(orderBy("lastMonthPriceDeltaPercent", "asc"));
          params.push(where("lastMonthPriceDeltaPercent", "<", 0));
          break;
        default:
          break;
      }
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const q = query(...params);
    const querySnapshot = await getDocs(q);

    return Promise.all(
      querySnapshot.docs.map((doc) => formatSymbolLightData(doc))
    );
  } catch (err) {
    console.error(err);
    return [];
  }
};

export const get = async (symbolId: string): Promise<StockSymbol> => {
  const collectionRef = collection(db, "symbols");
  const symbolDocRef = doc(collectionRef, symbolId);

  const symbolSnapshot = await getDoc(symbolDocRef);
  const symbolData = symbolSnapshot.data() as StockSymbolData;

  const picture = await getSymbolPicture(symbolData);

  return {
    id: symbolSnapshot.id,
    name: symbolData.name || "",
    description: symbolData.description || "",
    analystSentiment: symbolData.analystSentiment || undefined,
    category: symbolData.category || "",
    exchange: symbolData.exchange || "",
    updatedAt: symbolData.updatedAt,
    label: symbolData.label || "",
    picture,
    price: symbolData.price || 0,
    datapoints: symbolData.datapoints,
    lastDayPriceData: normalizeChartData(symbolData.lastDayPriceData),
    lastDayPrice: symbolData.lastDayPrice || 0,
    lastDayPriceDeltaPercent: symbolData.lastDayPriceDeltaPercent || 0,
    lastDayPriceDeltaPercentAbsolute:
      symbolData.lastDayPriceDeltaPercentAbsolute || 0,

    lastWeekPriceData: normalizeChartData(symbolData.lastWeekPriceData),
    lastWeekPrice: symbolData.lastWeekPrice || 0,
    lastWeekPriceDeltaPercent: symbolData.lastWeekPriceDeltaPercent || 0,
    lastWeekPriceDeltaPercentAbsolute:
      symbolData.lastWeekPriceDeltaPercentAbsolute || 0,

    lastMonthPriceData: normalizeChartData(symbolData.lastMonthPriceData),
    lastMonthPrice: symbolData.lastMonthPrice || 0,
    lastMonthPriceDeltaPercent: symbolData.lastMonthPriceDeltaPercent || 0,
    lastMonthPriceDeltaPercentAbsolute:
      symbolData.lastMonthPriceDeltaPercentAbsolute || 0,

    lastHalfYearPriceData: normalizeChartData(symbolData.lastHalfYearPriceData),
    lastHalfYearPrice: symbolData.lastHalfYearPrice || 0,
    lastHalfYearPriceDeltaPercent:
      symbolData.lastHalfYearPriceDeltaPercent || 0,
    lastHalfYearPriceDeltaPercentAbsolute:
      symbolData.lastHalfYearPriceDeltaPercentAbsolute || 0,

    lastYearPriceData: normalizeChartData(symbolData.lastYearPriceData),
    lastYearPrice: symbolData.lastYearPrice || 0,
    lastYearPriceDeltaPercent: symbolData.lastYearPriceDeltaPercent || 0,
    lastYearPriceDeltaPercentAbsolute:
      symbolData.lastYearPriceDeltaPercentAbsolute || 0,

    lastFiveYearsPriceData: normalizeChartData(
      symbolData.lastFiveYearsPriceData
    ),
    lastFiveYearsPrice: symbolData.lastFiveYearsPrice || 0,
    lastFiveYearsPriceDeltaPercent:
      symbolData.lastFiveYearsPriceDeltaPercent || 0,
    lastFiveYearsPriceDeltaPercentAbsolute:
      symbolData.lastFiveYearsPriceDeltaPercentAbsolute || 0,

    allTimePriceData: normalizeChartData(symbolData.allTimePriceData),
    allTimePrice: symbolData.allTimePrice || 0,
    allTimePriceDeltaPercent: symbolData.allTimePriceDeltaPercent || 0,
    allTimePriceDeltaPercentAbsolute:
      symbolData.allTimePriceDeltaPercentAbsolute || 0,
  };
};

type GetListBySentimentProps = {
  startAfter?: string;
  filterBy: AnalyticsSortingEnum;
  limit?: number;
};

export const getListBySentiment = async ({
  startAfter,
  filterBy,
  limit = 10,
}: GetListBySentimentProps): Promise<StockSymbolLight[]> => {
  try {
    const collectionRef = collection(db, "symbols");
    let params = [collectionRef, limitFunction(limit)];

    if (startAfter) {
      const lastDocumentSnap = await getDoc(doc(collectionRef, startAfter));
      params.push(startAt(lastDocumentSnap));
    }

    params.push(orderBy("analystSentiment", "asc"));
    params.push(where("analystSentiment", "!=", null));
    params.push(where("analystSentiment", ">", 0));

    switch (filterBy) {
      case AnalyticsSortingEnum.STRONG_BUY:
        params.push(where("analystSentiment", "<=", 1.5));
        break;
      case AnalyticsSortingEnum.BUY:
        params.push(where("analystSentiment", ">", 1.5));
        params.push(where("analystSentiment", "<=", 2.5));
        break;
      case AnalyticsSortingEnum.HOLD:
        params.push(where("analystSentiment", ">", 2.5));
        params.push(where("analystSentiment", "<=", 3.5));
        break;
      case AnalyticsSortingEnum.SELL:
        params.push(where("analystSentiment", ">", 3.5));
        params.push(where("analystSentiment", "<=", 4.5));
        break;
      case AnalyticsSortingEnum.STRONG_SELL:
        params.push(where("analystSentiment", ">", 4.5));
        break;
      default:
        break;
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const q = query(...params);
    const querySnapshot = await getDocs(q);

    return Promise.all(
      querySnapshot.docs.map((doc) => formatSymbolLightData(doc))
    );
  } catch (err) {
    console.error(err);
    return [];
  }
};

type GetListByBrandwatchAnalysisProps = {
  rank: BrandwatchSortingEnum;
  timeframe: BrandwatchTimeEnum;
};

export const getListByBrandwatchRedditAnalysis = async ({
  rank,
  timeframe,
}: GetListByBrandwatchAnalysisProps): Promise<
  StockSymbolLightWithRedditAnalysis[]
> => {
  try {
    const collectionRef = collection(db, "symbols");
    let params = [collectionRef, limitFunction(10)];

    params.push(orderBy(`redditAnalysis.${timeframe}.${rank}Upvotes`, "desc"));
    params.push(where(`redditAnalysis.${timeframe}.${rank}Upvotes`, ">", 0));

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const q = query(...params);
    const querySnapshot = await getDocs(q);

    return Promise.all(
      querySnapshot.docs.map((doc) =>
        formatSymbolLightDataWithRedditAnalysis(doc)
      )
    );
  } catch (err) {
    console.error(err);
    return [];
  }
};

export const getListByBrandwatchYoutubeAnalysis = async ({
  rank,
  timeframe,
}: GetListByBrandwatchAnalysisProps): Promise<
  StockSymbolLightWithYoutubeAnalysis[]
> => {
  try {
    const collectionRef = collection(db, "symbols");
    let params = [collectionRef, limitFunction(10)];

    params.push(orderBy(`youtubeAnalysis.${timeframe}.${rank}Total`, "desc"));
    params.push(where(`youtubeAnalysis.${timeframe}.${rank}Total`, ">", 0));

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const q = query(...params);
    const querySnapshot = await getDocs(q);

    return Promise.all(
      querySnapshot.docs.map((doc) =>
        formatSymbolLightDataWithYoutubeAnalysis(doc)
      )
    );
  } catch (err) {
    console.error(err);
    return [];
  }
};

export const getListByBrandwatchTwitterAnalysis = async ({
  startAfter,
}: {
  startAfter?: string;
}): Promise<StockSymbolLightWithTwitterAnalysis[]> => {
  try {
    const collectionRef = collection(db, "symbols");
    let params = [collectionRef, limitFunction(10)];

    params.push(orderBy("twitterAnalysis.24hours.mentionsCount", "desc"));

    if (startAfter) {
      const lastDocumentSnap = await getDoc(doc(collectionRef, startAfter));
      params.push(startAt(lastDocumentSnap));
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const q = query(...params);
    const querySnapshot = await getDocs(q);

    return Promise.all(
      querySnapshot.docs.map((doc) =>
        formatSymbolLightDataWithTwitterAnalysis(doc)
      )
    );
  } catch (err) {
    console.error(err);
    return [];
  }
};

const formatLastMonthPriceData = (
  priceData?: PriceData[] | string
): Pick<Price, "price" | "timestamp">[] => {
  if (!priceData) {
    return [];
  }

  if (Array.isArray(priceData)) {
    return priceData.map((data) => ({
      price: data.price,
      timestamp: data.datetime.toDate(),
    }));
  }

  return priceData.split("|").map((data) => {
    const [timestamp, open, high, low, close, volume] = data.split(",");
    return {
      price: Number(close),
      timestamp: new Date(timestamp),
    };
  });
};

const getSymbolPicture = async (symbol: StockSymbolData): Promise<string> => {
  try {
    return await getDownloadURL(ref(store, symbol.picture));
  } catch (e) {
    console.error(e);
    return "";
  }
};

const getPicture = async (picture: string): Promise<string> => {
  try {
    return await getDownloadURL(ref(store, picture));
  } catch (e) {
    console.error(e);
    return "";
  }
};

export const formatSymbolLightData = async (
  symbol: QueryDocumentSnapshot
): Promise<StockSymbolLight> => {
  const pictureUrl = await getPicture(symbol.get("picture"));

  return {
    id: symbol.id,
    label: symbol.get("label") || "",
    name: symbol.get("name") || "",
    description: symbol.get("description") || "",
    category: symbol.get("category") || "",
    updatedAt: symbol.get("updatedAt"),
    price: symbol.get("price") || 0,
    picture: pictureUrl,
    lastDayPriceDeltaPercent: symbol.get("lastDayPriceDeltaPercent") || 0,
    lastWeekPriceDeltaPercent: symbol.get("lastWeekPriceDeltaPercent") || 0,
    lastMonthPriceDeltaPercent: symbol.get("lastMonthPriceDeltaPercent") || 0,
    analystSentiment: symbol.get("analystSentiment"),
    datapoints: symbol.get("datapoints"),
  } as StockSymbolLight;
};

export const formatSymbolLightDataWithRedditAnalysis = async (
  symbol: QueryDocumentSnapshot
): Promise<StockSymbolLightWithRedditAnalysis> => {
  const data = symbol.data() as StockSymbolData;

  const symbolLightData = await formatSymbolLightData(symbol);

  return {
    ...symbolLightData,
    redditAnalysis: data.redditAnalysis,
  } as StockSymbolLightWithRedditAnalysis;
};

export const formatSymbolLightDataWithYoutubeAnalysis = async (
  symbol: QueryDocumentSnapshot
): Promise<StockSymbolLightWithYoutubeAnalysis> => {
  const data = symbol.data() as StockSymbolData;

  const symbolLightData = await formatSymbolLightData(symbol);

  return {
    ...symbolLightData,
    youtubeAnalysis: data.youtubeAnalysis,
  } as StockSymbolLightWithRedditAnalysis;
};

export const formatSymbolLightDataWithTwitterAnalysis = async (
  symbol: QueryDocumentSnapshot
): Promise<StockSymbolLightWithTwitterAnalysis> => {
  const data = symbol.data() as StockSymbolData;

  const symbolLightData = await formatSymbolLightData(symbol);

  return {
    ...symbolLightData,
    twitterAnalysis: data.twitterAnalysis,
    tradingVolumeDailyChange: data.tradingVolumeDailyChange,
  } as StockSymbolLightWithTwitterAnalysis;
};

const normalizeChartData = (data?: string | null) => {
  if (!data) {
    return [];
  }

  if (data.length === 0) {
    return [];
  }

  if (Array.isArray(data)) {
    return data.map((item) => ({
      timestamp: (item.datetime ?? item.timestamp).toDate(),
      price: item.price,
    }));
  }

  return data.split("|").map((item: string) => ({
    timestamp: new Date(item.split(",")[0]),
    price: Number(item.split(",")[2]),
  }));
};
