import { formatRGB } from "utils/formatRGB";

export const COLOR_WHITE = "#FFFFFF";
export const COLOR_WHITE_WITH_OPACITY = "rgba(255,255,255,0.8)";
export const COLOR_GREY_WITH_OPACITY = "rgba(0,0,0,0.15)";
export const COLOR_GREY50 = "#E5E5E5";
export const COLOR_GREY100 = "#CAC8C8";
export const COLOR_GREY200 = "#9A9A9A";
export const COLOR_GREY300 = "#747474";
export const COLOR_GREY400 = "#5C5C5C";
export const COLOR_GREY450 = "#5F5F5F";
export const COLOR_GREY500 = "#424242";
export const COLOR_GREY600 = "#333333";
export const COLOR_GREY700 = "#1D1D1D";
export const COLOR_GREY800 = "#030303";
export const COLOR_BLACK = "#000000";

export const COLOR_EXINITY_BLACK = "#0D0819";
export const COLOR_EXINITY_DARK_BLUE = "#13106D";
export const COLOR_EXINITY_LIGHT_BLUE = "#0088BF";
export const COLOR_EXINITY_CORAL = "#D46B6A";
export const COLOR_EXINITY_YELLOW = "#FFD089";
export const COLOR_EXINITY_GREEN = "#468B89";

export const COLOR_NEMO_PURPLE_DARK = "#2D075B";
export const COLOR_NEMO_VIOLET = "#4A01FF";
export const COLOR_NEMO_LIGHT_BLUE = "#5090FD";
export const COLOR_NEMO_AQUAMARINE = "#60FFFC";

export const COLOR_NEMO_SPRING_GREEN = "#00FF9E";
export const COLOR_NEMO_SPRING_GREEN_PALE = "#7AF8AF";
export const COLOR_NEMO_SPRING_GREEN_LIGHT = "#A7FCD2";

export const COLOR_NEMO_ORANGE = "#FF8A00";
export const COLOR_NEMO_YELLOW = "#FFFF05";
export const COLOR_NEMO_YELLOW_LIGHT = "#FCFACA";

export const COLOR_NEMO_RED = "#FE0100";
export const COLOR_NEMO_RED_LIGHT = "#FCCACA";
export const COLOR_NEMO_PINK = "#FE37C6";
export const COLOR_NEMO_ROSE = "#F9B2EC";
export const COLOR_NEMO_ROSE_LIGHT = "#FCD9FA";
export const COLOR_ALERTS_ERROR = "#FF484E";

export const COLOR_NEMO_BLUE_LIGHT = "#D9E7FC";

export const COLOR_BRANDBOOK_STEEL_GRAY = "#262438";

export const COLOR_WHITE_TRANSPARENT = (opacity = 0.8) =>
  `rgba(255, 255, 255, ${opacity})`;
export const COLOR_BLACK_TRANSPARENT = (opacity = 0.8) =>
  `rgba(0, 0, 0, ${opacity})`;
export const COLOR_TRANSPARENT = (color: string, opacity = 0.8) =>
  `rgba(${formatRGB(color)}, ${opacity})`;

export const COLOR_BLACK_5 = COLOR_BLACK_TRANSPARENT(0.05);
export const COLOR_BLACK_10 = COLOR_BLACK_TRANSPARENT(0.1);
export const COLOR_BLACK_20 = COLOR_BLACK_TRANSPARENT(0.2);
export const COLOR_BLACK_40 = COLOR_BLACK_TRANSPARENT(0.4);
export const COLOR_BLACK_60 = COLOR_BLACK_TRANSPARENT(0.6);
export const COLOR_BLACK_80 = COLOR_BLACK_TRANSPARENT(0.8);

export const COLOR_WHITE_5 = COLOR_WHITE_TRANSPARENT(0.05);
export const COLOR_WHITE_10 = COLOR_WHITE_TRANSPARENT(0.1);
export const COLOR_WHITE_20 = COLOR_WHITE_TRANSPARENT(0.2);
export const COLOR_WHITE_40 = COLOR_WHITE_TRANSPARENT(0.4);
export const COLOR_WHITE_60 = COLOR_WHITE_TRANSPARENT(0.6);
export const COLOR_WHITE_80 = COLOR_WHITE_TRANSPARENT(0.8);

export const BORDER = (width = 1, color = COLOR_WHITE_TRANSPARENT(0.2)) => {
  return `${width}px solid ${color}`;
};
