import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import * as firebase from "firebase/app";
import {
  browserLocalPersistence,
  connectAuthEmulator,
  indexedDBLocalPersistence,
  initializeAuth,
} from "firebase/auth";
import * as storage from "firebase/storage";
import * as firestore from "firebase/firestore";
import * as functions from "firebase/functions";
import { getRemoteConfig } from "firebase/remote-config";

const {
  initializeAppCheck,
  ReCaptchaV3Provider,
} = require("firebase/app-check");

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

if (process.env.REACT_APP_CAPTCHA_V3_SITE_KEY) {
  // Pass your reCAPTCHA v3 site key (public key) to activate(). Make sure this
  // key is the counterpart to the secret key you set in the Firebase console.
  const appCheck = initializeAppCheck(app, {
    provider: new ReCaptchaV3Provider(
      process.env.REACT_APP_CAPTCHA_V3_SITE_KEY
    ),

    // Optional argument. If true, the SDK automatically refreshes App Check
    // tokens as needed.
    isTokenAutoRefreshEnabled: true,
  });
}

// Initialize Cloud Firestore and get a reference to the service
export const authClient = initializeAuth(app, {
  persistence: [browserLocalPersistence, indexedDBLocalPersistence],
});
const functionsInstance = functions.getFunctions(firebase.getApp());

if (process.env.REACT_APP_USE_EMULATOR === "true") {
  console.log("using emulator connections");
  const isCypress = process.env.REACT_APP_RUN_FOR_CYPRESS === "true";
  const db = firestore.initializeFirestore(firebase.getApp(), {
    experimentalForceLongPolling: isCypress,
  });
  firestore.connectFirestoreEmulator(db, "localhost", 8080);
  functions.connectFunctionsEmulator(functionsInstance, "localhost", 5001);
  connectAuthEmulator(authClient, "http://localhost:9099");
  storage.connectStorageEmulator(storage.getStorage(), "localhost", 9199);
}

export const db = getFirestore(app);
export const store = getStorage(app);
export const remoteConfig = getRemoteConfig(app);

remoteConfig.settings.minimumFetchIntervalMillis = 3600000;
