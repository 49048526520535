import styled from "@emotion/styled";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import {
  COLOR_BLACK,
  COLOR_BLACK_5,
  COLOR_BLACK_TRANSPARENT,
  COLOR_EXINITY_LIGHT_BLUE,
  COLOR_GREY100,
  COLOR_GREY600,
  COLOR_GREY_WITH_OPACITY,
  COLOR_NEMO_SPRING_GREEN,
  COLOR_WHITE,
} from "view/theme/colors";
import { FONT_FAMILY_PRIMARY } from "view/theme";
import { border, flexCenter } from "view/theme/mixins";

export const StyledPrimaryButton = styled(Button)`
  font-weight: 500;
  font-family: ${FONT_FAMILY_PRIMARY};
  font-size: 1rem;
  text-transform: none;
  padding: 0 1.5rem;
  border-radius: 0;
  align-items: center;
  color: ${COLOR_WHITE};
  white-space: nowrap;
  transition: opacity 0.15s ease-in-out;
  cursor: pointer;

  &.small {
    height: 2rem;
    padding: 0 1.5rem;
    font-size: 0.75rem;
  }

  &.medium {
    height: 2.5rem;
  }

  &.large {
    height: 3rem;
  }

  &.flex {
    flex: 1;
  }

  &.link {
    color: ${COLOR_BLACK_TRANSPARENT(0.4)};
    text-decoration: underline;
    padding: 0.5rem;
    margin: -0.5rem;
    font-weight: 300;
    font-size: 10px;

    :hover {
      background-color: transparent;
    }
  }

  &.contained {
    color: ${COLOR_WHITE};
    background-color: ${COLOR_EXINITY_LIGHT_BLUE};

    &.nemo {
      color: ${COLOR_BLACK};
      background-color: ${COLOR_NEMO_SPRING_GREEN};
    }

    &.gray {
      color: ${COLOR_BLACK};
      background-color: ${COLOR_GREY100};
    }

    &.white {
      color: ${COLOR_BLACK};
      background-color: ${COLOR_WHITE};
      border-radius: 0.5rem;
      ${border(1, COLOR_BLACK_TRANSPARENT(0.15))}
    }

    &.two-rows {
      height: auto;
    }

    :hover {
      transition: opacity 0.15s ease-in-out;
      opacity: 1;
    }

    :disabled,
    &.loading {
      transition: opacity 0.15s ease-in-out;
      opacity: 0.2;
    }
  }

  &.ghost {
    color: ${COLOR_BLACK};

    :hover {
      transition: opacity 0.15s ease-in-out;
      background-color: ${COLOR_WHITE};
      opacity: 0.8;
    }

    :disabled,
    &.loading {
      transition: opacity 0.15s ease-in-out;
      opacity: 0.2;
    }
  }

  &.outlined {
    ${border(2, COLOR_BLACK)}
    color: ${COLOR_BLACK};

    :hover {
      opacity: 0.8;
    }

    :disabled,
    &.loading {
      opacity: 0.2;
    }
  }

  &.rounded {
    border-radius: 1.5rem;

    &.white {
      color: ${COLOR_BLACK};
      background-color: ${COLOR_WHITE};
    }

    &.gray {
      color: ${COLOR_BLACK};
      background-color: ${COLOR_BLACK_5};
    }
  }
`;

export const StyledLoadingContainer = styled(Box)`
  ${flexCenter()}
  width: 24px;
  height: 24px;

  .MuiCircularProgress-colorPrimary {
    color: ${COLOR_GREY600};
  }
`;

export const StyledIconContainer = styled(Box)`
  display: flex;

  &:not(.left) {
    margin-left: 0.5rem;
  }

  &.left {
    margin-right: 0.75rem;
  }
`;

export const StyledTwoRowsContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: start;
`;
