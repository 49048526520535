import { css, Global } from "@emotion/react";
import { FC } from "react";
import { COLOR_BLACK, COLOR_GREY700 } from "./colors";
import { media } from "./mixins";

const GlobalStyles: FC = () => (
  <Global
    styles={css`
      html {
        box-sizing: border-box;
      }

      #root {
        ${media("mobile", true)} {
          height: 100%;
          margin: 0 auto;
          width: 375px;
          background-color: ${COLOR_BLACK};
        }
      }

      body {
        ${media("mobile", true)} {
          background-color: ${COLOR_GREY700};
          max-height: calc(100% - 80px);
          margin: 40px auto;
          width: 100%;
          position: fixed;
          overflow: hidden;
          height: 100%;
        }

        background-color: ${COLOR_BLACK};
        margin: 0;
        padding: 0;

        p.firebase-emulator-warning {
          display: none;
        }
      }

      scroll-shadow {
        --scroll-shadow-left: linear-gradient(
          90deg,
          #000000 0%,
          rgba(0, 0, 0, 0) 100%
        );
        --scroll-shadow-right: linear-gradient(
          270deg,
          #000000 0%,
          rgba(0, 0, 0, 0) 100%
        );
      }
    `}
  />
);

export default GlobalStyles;
