import { Timestamp } from "firebase/firestore";

export type StockSymbol = {
  id: string;
  name: string;
  label: string;
  category: string;
  exchange?: string;
  description?: string;
  picture: string;
  analystSentiment: AnalystSentimentType;
  updatedAt: Date;
  price: number;
  lastDayPriceData: Pick<Price, "price" | "timestamp">[];
  lastDayPrice: number;
  lastDayPriceDeltaPercent: number;
  lastDayPriceDeltaPercentAbsolute: number;

  lastWeekPriceData: Pick<Price, "price" | "timestamp">[];
  lastWeekPrice: number;
  lastWeekPriceDeltaPercent: number;
  lastWeekPriceDeltaPercentAbsolute: number;

  lastMonthPriceData: Pick<Price, "price" | "timestamp">[];
  lastMonthPrice: number;
  lastMonthPriceDeltaPercent: number;
  lastMonthPriceDeltaPercentAbsolute: number;

  lastHalfYearPriceData: Pick<Price, "price" | "timestamp">[] | null;
  lastHalfYearPrice: number;
  lastHalfYearPriceDeltaPercent: number;
  lastHalfYearPriceDeltaPercentAbsolute: number;

  lastYearPriceData: Pick<Price, "price" | "timestamp">[] | null;
  lastYearPrice: number;
  lastYearPriceDeltaPercent: number;
  lastYearPriceDeltaPercentAbsolute: number;

  lastFiveYearsPriceData: Pick<Price, "price" | "timestamp">[] | null;
  lastFiveYearsPrice: number;
  lastFiveYearsPriceDeltaPercent: number;
  lastFiveYearsPriceDeltaPercentAbsolute: number;

  allTimePriceData: Pick<Price, "price" | "timestamp">[] | null;
  allTimePrice: number;
  allTimePriceDeltaPercent: number;
  allTimePriceDeltaPercentAbsolute: number;

  redditAnalysis?: RedditAnalysis;
  youtubeAnalysis?: YoutubeAnalysis;
  twitterAnalysis?: TwitterAnalysis;
  tradingVolumeDailyChange?: number;
  datapoints: Record<string, number | Timestamp>;
};

export type RedditMention = {
  added: string; // actually date
  author: string;
  avatarUrl: string;
  domain: string;
  guid: string; // actually number
  monthlyVisitors: number;
  originalUrl: string;
  pageType: string;
  pubType: string;
  publisherSubType: string;
  queryId: number;
  queryName: string;
  redditScore: number;
  snippet: string;
  threadEntryType: string;
  threadId: string; // actually number
  updated: string;
  url: string;
  title: string;
  pageTypeName: string;
  resourceId: string;
};

export type RedditAnalysis = {
  [BrandwatchTimeEnum.HOURS_24]: {
    commentsUpvotes: number;
    comments: RedditMention[];
    postsUpvotes: number;
    posts: RedditMention[];
  };
  [BrandwatchTimeEnum.DAYS_7]: {
    commentsUpvotes: number;
    comments: RedditMention[];
    postsUpvotes: number;
    posts: RedditMention[];
  };
};

export type YoutubeAnalysisItem = {
  comments: YoutubeMention[];
  commentsTotal: number;
  posts: YoutubeMention[];
  postsTotal: number;
};

export type YoutubeAnalysis = {
  [BrandwatchTimeEnum.HOURS_24]: YoutubeAnalysisItem;
  [BrandwatchTimeEnum.DAYS_7]: YoutubeAnalysisItem;
};

export type TwitterAnalysisItem = {
  mentionsCount: number;
  mentionsCountChange: number;
};

export type TwitterAnalysis = {
  [BrandwatchTimeEnum.HOURS_24]: TwitterAnalysisItem;
  brandwatchCategoryId: string;
  updatedAt: Date;
};

export type StockSymbolData = {
  id: string;
  name: string;
  label: string;
  category: string;
  exchange?: string;
  description?: string;
  picture: string;
  analystSentiment: AnalystSentimentType;
  updatedAt: Date;
  price: number;

  lastDayPriceData: string;
  lastDayPrice: number;
  lastDayPriceDeltaPercent: number;
  lastDayPriceDeltaPercentAbsolute: number;

  lastWeekPriceData: string;
  lastWeekPrice: number;
  lastWeekPriceDeltaPercent: number;
  lastWeekPriceDeltaPercentAbsolute: number;

  lastMonthPriceData: string;
  lastMonthPrice: number;
  lastMonthPriceDeltaPercent: number;
  lastMonthPriceDeltaPercentAbsolute: number;

  lastHalfYearPriceData: string | null;
  lastHalfYearPrice: number;
  lastHalfYearPriceDeltaPercent: number;
  lastHalfYearPriceDeltaPercentAbsolute: number;

  lastYearPriceData: string | null;
  lastYearPrice: number;
  lastYearPriceDeltaPercent: number;
  lastYearPriceDeltaPercentAbsolute: number;

  lastFiveYearsPriceData: string | null;
  lastFiveYearsPrice: number;
  lastFiveYearsPriceDeltaPercent: number;
  lastFiveYearsPriceDeltaPercentAbsolute: number;

  allTimePriceData: string | null;
  allTimePrice: number;
  allTimePriceDeltaPercent: number;
  allTimePriceDeltaPercentAbsolute: number;

  redditAnalysis?: RedditAnalysis;
  youtubeAnalysis?: YoutubeAnalysis;
  twitterAnalysis?: TwitterAnalysis;
  tradingVolumeDailyChange?: number;
  datapoints: Record<string, number | Timestamp>;
};

export type StockSymbolCreate = Omit<StockSymbol, "id" | "price">;

export type StockSymbolLight = Pick<
  StockSymbol,
  | "id"
  | "label"
  | "name"
  | "updatedAt"
  | "category"
  | "description"
  | "analystSentiment"
  | "picture"
  | "price"
  | "lastDayPriceDeltaPercent"
  | "lastWeekPriceDeltaPercent"
  | "lastMonthPriceDeltaPercent"
  | "datapoints"
  | "tradingVolumeDailyChange"
>;

export type StockSymbolLightWithRedditAnalysis = StockSymbolLight &
  Pick<StockSymbol, "redditAnalysis">;

export type StockSymbolLightWithYoutubeAnalysis = StockSymbolLight &
  Pick<StockSymbol, "youtubeAnalysis">;

export type StockSymbolLightWithTwitterAnalysis = StockSymbolLight &
  Pick<StockSymbol, "twitterAnalysis">;

export type AnalystSentimentType = 0 | 1 | 2 | 3 | 4 | 5 | undefined;

export type Price = {
  price: number;
  timestamp: Date;
  eodPrice: number;
  delta: number;
};

export type PriceData = {
  price: number;
  datetime: Timestamp;
  eodPrice: number;
};

export type YoutubeMention = {
  added: string; // actually date
  author: string;
  avatarUrl: string;
  domain: string;
  guid: string; // actually number
  monthlyVisitors: number;
  originalUrl: string;
  pageType: string;
  pubType: string;
  publisherSubType: string;
  queryId: number;
  queryName: string;
  redditScore: number;
  snippet: string;
  threadEntryType: string;
  threadId: string; // actually number
  updated: string;
  url: string;
  title: string;
  pageTypeName: string;
  resourceId: string;
};

export enum StockFilterPeriodType {
  ONE_MONTH = "1month",
  ONE_WEEK = "1week",
  ONE_DAY = "1day",
}

export enum BrandwatchTimeEnum {
  HOURS_24 = "24hours",
  DAYS_7 = "7days",
}

export enum BrandwatchSortingEnum {
  POSTS = "posts",
  COMMENTS = "comments",
}

export enum TopMoversSortingEnum {
  ALL = "all",
  GAINERS = "gainers",
  LOSERS = "losers",
}

export enum AnalyticsSortingEnum {
  ALL = "all",
  STRONG_BUY = "strongBuy",
  BUY = "buy",
  HOLD = "hold",
  SELL = "sell",
  STRONG_SELL = "strongSell",
}

export enum AnalyticsLabelEnum {
  ALL = "all",
  STRONG_BUY = "Strong Buy",
  BUY = "buy",
  HOLD = "hold",
  SELL = "sell",
  STRONG_SELL = "Strong Sell",
}

export enum StockFilterChartPeriodType {
  ONE_DAY = "1d",
  ONE_WEEK = "1w",
  ONE_MONTH = "1m",
  SIX_MONTHS = "6m",
  ONE_YEAR = "1y",
  FIVE_YEARS = "5y",
  ALL = "All",
}

export type ChartDataType = {
  changePercentage: number;
  changeValue: number;
  data: {
    price: number;
    timestamp: Date;
  }[];
};
