import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Transition from "view/components/shared/Transition";
import { COLOR_WHITE } from "view/theme/colors";
import { useInstallPrompt } from "view/pages/InstallPrompt/context";
import { NemoLogo } from "view/theme/logos";
import { CloseIcon } from "view/theme/icons";
import IconButton from "@mui/material/IconButton";
import { ShareIcon } from "view/pages/InstallPrompt/icons/iosShareIcon";
import useIosInstallPrompt from "hooks/pwa/useIosInstallPrompt";
import { mParticleEventTracker } from "utils/mParticle";
import {
  StyledBottomBox,
  StyledButton,
  StyledContainer,
  StyledDialog,
  StyledIconContainer,
  StyledShareIcon,
  StyledTitleInstall,
  StyledTypographyText,
} from "./styled";

const InstallAppPrompt: React.FC = () => {
  const { isOpen, closePopup, installApp } = useInstallPrompt();
  const [isIos] = useIosInstallPrompt();

  const handleInstallApp = () => {
    mParticleEventTracker("add_to_home");
    installApp();
  };

  const renderBottomBlock = () => {
    if (isIos) {
      return (
        <StyledBottomBox>
          <Typography
            data-qa="ios-button"
            variant="bodyLRegular"
            color={COLOR_WHITE}
          >
            Just tap
            <StyledShareIcon>
              <ShareIcon />
            </StyledShareIcon>
            then “Add to Home Screen”
          </Typography>
        </StyledBottomBox>
      );
    }
    return (
      <StyledButton
        data-qa="android-button"
        title="Add to Homescreen"
        onClick={handleInstallApp}
      />
    );
  };

  return (
    <StyledDialog
      open={isOpen}
      onClose={closePopup}
      TransitionComponent={Transition}
      fullScreen
    >
      <Box display="flex" justifyContent="flex-end" mt="1.5rem">
        <IconButton id="cy-close-button" onClick={closePopup}>
          <CloseIcon color={COLOR_WHITE} />
        </IconButton>
      </Box>
      <StyledContainer>
        <StyledIconContainer>
          <NemoLogo />
        </StyledIconContainer>
        <StyledTitleInstall
          data-qa="install-nemo"
          variant="h1"
          color={COLOR_WHITE}
          align="center"
        >
          Install Nemo
        </StyledTitleInstall>
        <StyledTypographyText
          data-qa="install-description"
          variant="bodyXLRegular"
          color={COLOR_WHITE}
        >
          Install the Nemo app on your home screen for quick and easy access
          when you’re on the go.
        </StyledTypographyText>
      </StyledContainer>
      {renderBottomBlock()}
    </StyledDialog>
  );
};

export default InstallAppPrompt;
