import { FC } from "react";
import { COLOR_GREY500 } from "view/theme/colors";
import Skeleton from "@mui/material/Skeleton";
import { Stack } from "@mui/material";

const FAQsSkeleton: FC = () => {
  const count = 12;

  return (
    <Stack spacing="4px">
      {Array.from({ length: count }).map((_, index) => (
        <Skeleton
          key={`faq-skeleton-${index * 3}`}
          variant="rectangular"
          height="48px"
          sx={{
            backgroundColor: COLOR_GREY500,
            borderRadius: "4px",
          }}
        />
      ))}
    </Stack>
  );
};

export default FAQsSkeleton;
