import { createContext, FC, useContext, useState } from "react";
import { AppContextType, AppContextProviderProps } from "./types";

const AppContext = createContext<AppContextType>({
  themesPageOffset: 0,
  setThemesPageOffset: () => null,
});

export const useApp = () => {
  return useContext(AppContext);
};

const AppProvider: FC<AppContextProviderProps> = ({ children }) => {
  const [themesPageOffset, setThemesPageOffset] = useState(0);

  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const value = {
    themesPageOffset,
    setThemesPageOffset,
  };

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};

export default AppProvider;
