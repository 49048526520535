import styled from "@emotion/styled";
import Box from "@mui/material/Box";
import MuiDialog from "@mui/material/Dialog";
import { COLOR_WHITE } from "view/theme/colors";
import PrimaryButton from "view/components/shared/PrimaryButton";
import theme from "view/theme";
import Typography from "@mui/material/Typography";
import { media } from "view/theme/mixins";

export const StyledTitleInstall = styled(Typography)`
  &.MuiTypography-root {
    font-size: 3rem;

    ${theme.breakpoints.down(340)} {
      font-size: 2.75rem;
    }

    ${theme.breakpoints.down(320)} {
      font-size: 2.5rem;
    }
  }
`;

export const StyledContainer = styled(Box)`
  &.MuiBox-root {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2rem;
  }
`;

export const StyledIconContainer = styled(Box)`
  &.MuiBox-root {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    border-radius: 2.25rem;
    text-transform: none;
    letter-spacing: 0;
    color: ${COLOR_WHITE};
    font-weight: 700;
    margin-bottom: 0.5rem;

    & > svg {
      width: 50px;
      height: 50px;
    }
  }
`;

export const StyledBox = styled(Box)`
  &.MuiBox-root {
    display: flex;
    width: 155px;
    flex-direction: column;
    align-items: center;
  }
`;

export const StyledPrimaryButton = styled(PrimaryButton)`
  &.MuiButton-root {
    height: 4rem;
    text-transform: none;
    letter-spacing: 0;
    color: ${COLOR_WHITE};
    font-weight: 700;
  }
`;

export const StyledTypography = styled(Typography)`
  &.MuiTypography-root {
    text-align: center;
    line-height: 1.5;
  }
`;
export const StyledTypographyText = styled(StyledTypography)`
  &.MuiTypography-root {
    margin-top: 1rem;
  }
`;

export const StyledButton = styled(PrimaryButton)`
  &.MuiButton-root {
    border-radius: 4px;
    width: 100%;
    margin-bottom: 24px;
  }
`;

export const StyledBottomBox = styled(Box)`
  margin-bottom: 24px;
  text-align: center;
  height: 2.5rem;
`;

export const StyledDialog = styled(MuiDialog)`
  & .MuiDialog-container {
    ${media("desktop", true)} {
      width: 375px;
      margin: 40px auto;
      height: calc(100% - 80px);
    }
  }

  & .MuiPaper-root {
    background-image: url("/images/prompt-background.webp");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 1rem;
    justify-content: center;
  }
`;

export const StyledShareIcon = styled("span")`
  vertical-align: middle;
  margin: 0 0.5rem;
`;
